import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import dashboardIcon from "../../assets/dashboard.png";
import taskIcon from "../../assets/tasks.png";
import starIcon from "../../assets/stars.png";
import userIcon from "../../assets/user.png";
import phone from "../../assets/iphone.png";
import monthlyicon from "../../assets/calendar.png";
import helpicon from "../../assets/question.png";
import invoice from "../../assets/invoice.png";
import telephone from "../../assets/telephone.png";
import adminpng from "../../assets/admin.png";
import { AuthContext } from "../../context/AuthContext";
import { FiPlusCircle } from "react-icons/fi";

function Sidenav() {
	const { adminData, user } = useContext(AuthContext);
	console.log(adminData?.name !== "Mark");

	return (
		<aside className="w-full max-w-[270px] min-w-[270px] h-screen overflow-auto p-6 sticky top-0">
			<p className="text-xl font-semibold">Ready Steady Portal</p>
			<div className="divider"></div>
			<div>
				<div>
					<NavLink to="/admin/new-deal-request" className="sidenav-btn hover:bg-base-200 justify-center bg-primary text-white">
						<FiPlusCircle />
						<span className="font-[600] text-[14px]">New Deal</span>
					</NavLink>

					<NavLink to="/admin/new-kanban" className="sidenav-btn hover:bg-base-200 animatedButton">
						<div className="w-7">
							<img src={taskIcon} className="h-6" alt="Home icon" />
						</div>
						<span className="font-[600] text-[14px]">New Kanban</span>
						<img src={starIcon} className="h-6" alt="Home icon" />
					</NavLink>
					{(adminData?.role === "super-admin" || adminData?.name === "Millie" || adminData?.name === "Hena") && (
						<NavLink to="/admin/new-dashboard" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={dashboardIcon} className="h-6" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">New Dashboard</span>
						</NavLink>
					)}
					{user?.email !== "max@swiftfund.co.uk" && adminData?.name !== "Millie" && adminData?.name !== "Hena" && (
						<NavLink to="/admin/callbacklist" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={phone} className="h-7 w-5" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">Call Back List</span>
						</NavLink>
					)}

					{adminData?.role === "super-admin" && (
						<NavLink to="/admin/invoice-customer" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={invoice} className="h-6" alt="Dashboard icon" />
							</div>
							<span className="font-[600] text-[14px]">Invoice Customers</span>
						</NavLink>
					)}

					{(adminData?.role === "super-admin" || user?.email === "jon@readysteadyfinance.co.uk" || user?.email === "steven@sedgefunding.co.uk") && (
						<NavLink to="/admin/staff-review" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={userIcon} className="h-6" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">Staff Review</span>
						</NavLink>
					)}
					{adminData?.role !== "super-admin" && user?.email !== "max@swiftfund.co.uk" ? (
						<NavLink to={`/admin/single-staff-review/${adminData.name}`} className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={userIcon} className="h-6" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">My Review</span>
						</NavLink>
					) : (
						<></>
					)}
					{adminData?.role === "super-admin" && (
						<NavLink to="/admin/management/book" className="!gap-2 sidenav-btn hover:bg-base-200">
							<div className="w-8">
								<img src={adminpng} className="h-8" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">Admin</span>
						</NavLink>
					)}

					{user?.email !== "max@swiftfund.co.uk" && (
						<NavLink to="/admin/calender" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={monthlyicon} className="h-6" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">Calendar</span>
						</NavLink>
					)}

					{(adminData?.role === "super-admin" || adminData["access-level"] === "0" || adminData?.name !== "Mark") && (
						<NavLink to="/admin/qa-bonus" className="sidenav-btn hover:bg-base-200">
							<div className="w-7">
								<img src={telephone} className="h-6" alt="Home icon" />
							</div>
							<span className="font-[600] text-[14px]">Staff Calls</span>
						</NavLink>
					)}

					<NavLink to="/admin/company-info" className="sidenav-btn hover:bg-base-200">
						<div className="w-7">
							<img src={helpicon} className="h-7" alt="Home icon" />
						</div>
						<span className="font-[600] text-[14px]">Help</span>
					</NavLink>
				</div>
			</div>
		</aside>
	);
}

export default Sidenav;
