import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import axios from 'axios';

const NewPitch = () => {
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loanData, setLoanData] = useState({});
    const [teamNote, setTeamNote] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');

    const handleWheel = (e) => {
        e.preventDefault(); 
        e.target.blur();    
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault(); 
        }
    };

    // Form Data
    const [formData, setFormData] = useState(loanData);

    const updateLoanData = async () => {
        const updatedLoanDataObject = {
            ...formData
        };
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/update/new-pitch/${loanData?._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                email: user.email,
                data: updatedLoanDataObject
            })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        navigate(-1);
    }

    const updateLoanDatatwo = async () => {
        const updatedLoanDataObject = {
            ...formData
        };

        const token = localStorage.getItem('token'); 

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/update/new-pitch/${loanData?._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                email: user.email,
                data: updatedLoanDataObject,
            })
        });
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/update-status/${loanData?._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    email: user.email,
                    'status': 'Pre-Underwriting',
                    'lender': 'New Deal'
                })
            });

            if (response.ok) {
                console.log('successfull');
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }

        navigate('/admin/new-kanban')
    }

    useEffect(() => {
        setLoading(true)
        const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/single-deal/${id}`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user?.email })
        })
            .then(response => response.json())
            .then(data => {
                setLoanData(data);
                setFormData({ 'marchent-turnover': data['marchent-turnover'], 'avarage-monthly-turnover': data['avarage-monthly-turnover'] });
                setLoading(false);
                if (data?.updated === true) {
                    let updatedLoanDataObject = {};
                    if (data?.seenby.includes(user?.email)) {
                        updatedLoanDataObject = {
                            "seenby": [...data?.seenby]
                        }
                    } else if (!data?.seenby) {
                        updatedLoanDataObject = {
                            "seenby": [...data?.seenby, user?.email]
                        }
                    }
                    if (!data?.seenby.includes(user?.email)) {
                        updatedLoanDataObject = {
                            "seenby": [...data?.seenby, user?.email]
                        }
                    }

                    fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${id}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(updatedLoanDataObject)
                    })
                        .then(response => response.json())
                        .then(result => { })
                        .catch(error => { });
                }

            })
            .catch(error => {
                console.error(error);
            });

    }, [id, user?.email])

    const handleCallBackSubmit = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/add-callback/${loanData._id}`, { callBack: { date: selectedDate, time: selectedTime } }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.modifiedCount) {
                navigate(-1)
            }
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const generateTimeSlots = () => {
        const timeSlots = [];
        const startTime = 8 * 60; 
        const endTime = 20 * 60; 
        const interval = 15; 

        for (let i = startTime; i < endTime; i += interval) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const displayHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedTime = `${displayHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
            timeSlots.push(formattedTime);
        }

        return timeSlots;
    };

    const handleNoteSubmit = () => {
        setLoading(true)
        const userDisplayName = user["displayName"];
        const currentTime = new Date();
        const options = { timeZone: "Europe/London", year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedTime = currentTime.toLocaleString("en-GB", options);

        const note = {
            name: `${userDisplayName} - ${formattedTime}`,
            note: teamNote
        };
        if (teamNote.length !== 0) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/add-note/${loanData?._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ lenderName: 'New Deal', note }),
            })
                .then(response => response.json())
                .then(result => {
                    if (result.success) {
                        setLoading(false)
                        setTeamNote('');
                        navigate('/admin/new-kanban')
                    } else {

                        console.error(result.message);
                        setLoading(false)
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    if (loading) {
        return <div className='font-bold text-4xl flex h-screen justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }


    return (
        <div>
            <div className='p-10 pl-0'>
                <div className='text-xl mb-5'>
                    Hi May I speak to  <span className='font-semibold'>{loanData["business-owner-name"] || '-'}</span> (Don’t ask for the business owner) <br /><br />
                    <span className='font-semibold text-red-500'>NOT IN</span> - no problem when will they be back, its not hugely important just don’t want to keep bugging you that’s all. <br />

                    <div className='flex items-center mt-5'>
                        <input type="date" id="date" name="date" value={selectedDate} onChange={handleDateChange} className='input border-gray-400 border mr-3' required />
                        <select id="time" name="time" className='input border-gray-400 border mr-3' value={selectedTime} onChange={handleTimeChange} required>
                            <option value="">Select a time</option>
                            {generateTimeSlots().map((timeSlot, index) => (
                                <option key={index} value={timeSlot}>{timeSlot}</option>
                            ))}
                        </select>
                        <button type='button' onClick={handleCallBackSubmit} className='btn bg-primary text-white'>Call Back</button>
                    </div>
                    <br />

                    <span className='font-semibold text-green-500'>IN</span> - This is <span className='font-semibold '>{user?.displayName}</span> calling from Ready Steady Finance. How are you doing, How’s the business so far?

                    <br /><br />
                    Awesome!!
                    <br /><br />

                    Just a quick one, Have you been applying for a business Loan/MCA?<br /><br />
                    OK, no worries. Im here to help you. <br /><br />
                    Yeah the reason why im calling is to let you know that our company is providing  no obligation services whether a small to medium businesses in getting funding or any other type of funding that the business might need.<br /><br />
                    So What we do is help you apply for Business loans, MCA or Invoice Finance. Just to add, once you have been approved for a MCA or a business loan, you are not obligated to accept nor there will be no lock in contracts right away so you can review the offer in detail before you accept.<br /><br />

                    If you gave me one shot on this, believe me {loanData["business-owner-name"] || '-'} the only thing I can assure you 100% is that you’ve found a finance company that you can trust and consistently make your business grow. <br /><br />

                    Just need to confirm some of the Basic information and it will be very Quick.<br /><br />
                </div>

                <div className="divider my-5"></div>

                <form className='p-10 pl-0'>
                    <div className='w-1/2'>
                        <label htmlFor="business-notes" className='text-xl mb-1 block'>What type of business do you have?</label>
                        <input onChange={handleChange} defaultValue={`${loanData["business-notes"]}`} name='business-notes' type="text" placeholder="Notes" className="p-3 rounded-lg bg-base-300 w-full" />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="loan-purpose" className='text-xl mb-1 block'>
                            1) Is there any new equipment you need? <br />
                            2) What market idea for the future?
                        </label>
                        <input onChange={handleChange} defaultValue={`${loanData["loan-purpose"]}`} name='loan-purpose' type="text" placeholder="Loan Purpose" className="p-3 rounded-lg bg-base-300 w-full" />
                    </div>

                    <p className='text-lg'>
                        Let look what info I have.<br />
                        Following information:<br /><br />
                    </p>

                    <div className='mb-4 w-1/2'>
                        <label htmlFor="business-name" className='text-xl mb-1 block'>So, I have the business name as:</label>
                        <input onChange={handleChange} name='business-name' id='business-name' type="text" placeholder="Business Name" defaultValue={`${loanData["business-name"]}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="business-address" className='text-xl mb-1 block'>And the address you are registered?</label>
                        <input onChange={handleChange} defaultValue={`${loanData["business-address"]}`} name='business-address' id='business-address' type="text" placeholder="Business Address" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="business-owner-name" className='text-xl mb-1 block'>Contact Name:</label>
                        <input onChange={handleChange} name='business-owner-name' id='business-owner-name' type="text" placeholder="Business Contact Name" defaultValue={`${loanData["business-owner-name"] || '-'}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="business-phone" className=' text-xl mb-1 block'>Business Phone Number:</label>
                        <input onWheel={handleWheel} onKeyDown={handleKeyDown} onChange={handleChange} defaultValue={`${loanData["business-phone"]}`} name='business-phone' id='business-phone' type="number" placeholder="Phone Number" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="businessEmail" className=' text-xl mb-1 block'>Email Address:</label>
                        <input onChange={handleChange} defaultValue={`${loanData["business-email"]}`} name='business-email' id='businessEmail' type="text" placeholder="Email" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="businessTurnover" className=' text-xl mb-1 block'>How much is your Turnover every month? -<span className='text-primary'> Current Value: {loanData["avarage-monthly-turnover"]}</span></label>
                        <input onWheel={handleWheel} onKeyDown={handleKeyDown} onChange={handleChange} defaultValue={`${loanData["avarage-monthly-turnover"]}`} name='avarage-monthly-turnover' type="number" placeholder="Average Monthly Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="marchent-turnover" className=' text-xl mb-1 block'>
                            1) Is any of that turn over using websites like your own or ebay amazon <br />
                            2) Any by a card machine or epos est ... -<span className='text-primary'> Current Value: {loanData["marchent-turnover"]}</span>
                        </label>
                        <input onChange={handleChange} defaultValue={`${loanData["marchent-turnover"]}`} name='marchent-turnover' onWheel={handleWheel} onKeyDown={handleKeyDown} type="number" placeholder="Marchent Turnover" className="p-3 rounded-lg bg-base-300 w-full" />
                    </div>
                    <div className='my-8 w-1/2'>
                        <label htmlFor="invoice-amount" className=' text-xl mb-1 block'>And what % is just invoices?</label>
                        <input onChange={handleChange} name='invoice-amount' id='invoice-amount' type="text" placeholder="Do they Invoice" defaultValue={`${loanData["invoice-amount"]}`} className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>

                    <div>
                        <p className='text-xl'>
                            Based on the information supplied we would look to offer you - <br />
                            MCA - £ {((parseFloat(formData["marchent-turnover"]) || 0) * 1.5).toLocaleString()}<br />
                            1 Year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 1.5).toLocaleString()}<br />
                            2 Year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 2).toLocaleString()}<br />
                            3 year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 3).toLocaleString()}<br />
                        </p>
                    </div>


                    <div className='text-xl'>
                        If you are happy with the offer you can take it and if you don’t like it you can have this offer sit in the email and contact us back if you decided to take the offer. <br /><br />

                        Are you happy for us to do a credit check on your business to get you an offer? <br /><br />

                        <div className='flex gap-8'>
                            <div className="form-control w-[70px]">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Yes</span>
                                    <input
                                        onChange={handleChange}
                                        type="checkbox"
                                        name="creditCheckPermissionYes"
                                        defaultChecked={loanData.creditCheckPermissionYes || false}
                                        className="checkbox"
                                    />
                                </label>
                            </div>
                            <div className="form-control w-[70px] ">
                                <label className="label cursor-pointer">
                                    <span className="label-text">No</span>
                                    <input
                                        onChange={handleChange}
                                        type="checkbox"
                                        name="creditCheckPermissionNo"
                                        defaultChecked={loanData.creditCheckPermissionNo || false}
                                        className="checkbox"
                                    />
                                </label>
                            </div>
                        </div>


                        <br />
                        Thank you for all the information. One of our Funding Manager will get back to you on this and assist you further to get some offers sorted.
                        <br /><br />
                        Sounds fair enough?<br />
                        Thank you have a Great Day !
                    </div>
                    <div className='mt-4 text-right'>
                        <button type='button' onClick={(e) => updateLoanData(e)} className='button !rounded mr-3'>Save for Later</button>
                        {
                            loading ? <button className="btn">
                                <span className="loading loading-spinner"></span>
                                loading
                            </button> : <button type='button' onClick={updateLoanDatatwo} className='button !rounded'>Submit to Underwriting</button>
                        }
                    </div>
                </form>
            </div>
            <div className='mb-96'>
                <p className='text-3xl font-semibold'>Customer Not Interested?</p>
                <div className='w-1/2 mt-8'>
                    <label htmlFor="home-address" className='text-xl mb-1 block'>Why Customer is not interested now?</label>
                    <textarea
                        id='not-interested'
                        type="text"
                        placeholder="Tell us more about it !"
                        className="textarea textarea-md bg-base-300 w-full max-w-xs"
                        value={teamNote}
                        onChange={(e) => setTeamNote(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type='button' onClick={handleNoteSubmit} className='px-6 py-3 bg-primary text-white rounded-md'>Send</button>
            </div>
        </div>
    )
}

export default NewPitch