import { createBrowserRouter } from "react-router-dom";
import Main from "../../layout/Main";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Login from "../../pages/Login/Login";
import User from "../../pages/User/User";
import CustomerLogin from "../../pages/CustomerLogin/CustomerLogin";
import CustomerDashboard from "../../pages/CustomerDashboard/CustomerDashboard";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Customer from "../../layout/Customer";
import Register from "../../pages/Register/Register";
import CustomerProfile from "../../pages/CustomerProfile/CustomerProfile";
import Book from "../../pages/Book/Book";
import AdminRoute from "../AdminRoute/AdminRoute";
import Monthly from "../../pages/Monthly/Monthly";
import FundingRequest from "../../pages/FundingRequest/FundingRequest";
import ForgetPassword from "../../pages/ForgetPassword/ForgetPassword";
import RequestLoan from "../../components/RequestLoan/RequestLoan";
import Mca from "../../components/CreateNewLoan/Mca";
import BusinessLoan from "../../components/CreateNewLoan/BusinessLoan";
import InvoiceFinance from "../../components/CreateNewLoan/InvoiceFinance";
import Grants from "../../components/CreateNewLoan/Grants";
import EditLoanPage from "../../pages/EditLoanPage/EditLoanPage";
import UpdateUser from "../../pages/UpdateUser/UpdateUser";
import Kanaban from "../../pages/Kanaban/Kanaban";
import Trash from "../../pages/Trash/Trash";
import Calender from "../../pages/Calender/Calender";
import QaBonus from "../../pages/QaBonus/QaBonus";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import CanceledDeals from "../../pages/CanceledDeals/CanceledDeals";
import EditUser from "../../pages/EditUser/EditUser";
import AddUser from "../../pages/AddUser/AddUser";
import ContinueToPreUnderwriting from "../../components/EditLoan/ContinueToPreUnderwriting";
import CompanyInfoPage from "../../pages/CompanyInfoPage/CompanyInfoPage";
import CallBackList from "../../pages/CallBackList/CallBackList";
import { OpenBankingList } from "../../pages/OpenBankingList/OpenBankingList";
import AllStatus from "../../pages/AllStatus/AllStatus";
import UserManagement from "../../pages/UserManagement/UserManagement";
import SignAgreement from "../../components/SignAgreement/SignAgreement";
import AgreementForm from "../../components/AgreementForm/AgreementForm";
import ThankYou from "../../pages/ThankYou/ThankYou";
import InvoiceCustomers from "../../pages/InvoiceCustomers/InvoiceCustomers";
import Underwriting from "../../components/EditLoan/Underwriting";
import NewKanban from "../../pages/NewKanban/NewKanban";
import AddSingleDeal from "../../components/AddSingleDeal/AddSingleDeal";
import EditSingleDeal from "../../components/EditSingleDeal/EditSingleDeal";
import NewPitch from "../../components/NewPitch/NewPitch";
import NewUnderwritting from "../../components/NewUnderwritting/NewUnderwritting";
import NewDashboard from "../../pages/NewDashboard/NewDashboard";
import StaffReview from "../../pages/StaffReview/StaffReview";
import SingleStaffReview from "../../pages/SingleStaffReview/SingleStaffReview";
import WeeklyAppraisals from "../../pages/WeeklyAppraisals/WeeklyAppraisals";
import SingleInvoiceCustomer from "../../pages/SingleInvoiceCustomer/SingleInvoiceCustomer";
import ApplyForFunding from "../../pages/ApplyForFunding/ApplyForFunding";
import Historic from "../../components/Historic/Historic";
import Roles from "../../components/Roles/Roles";
import RequestNewDeal from "../../components/RequestNewDeal/RequestNewDeal";
import NewDealActionPage from "../../components/NewDealActionPage/NewDealActionPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <CustomerLogin></CustomerLogin>,
    },
    {
        path: "/register",
        element: <Register></Register>,
    },
    {
        path: "/forget-password",
        element: <ForgetPassword></ForgetPassword>,
    },
    {
        path: "/apply-for-funding/:company/:country",
        element: <ApplyForFunding></ApplyForFunding>,
    },
    {
        path: "/agreement-form/:id",
        element: <AgreementForm />,
    },
    {
        path: "/sign-document/:id",
        element: <SignAgreement />,
    },
    {
        path: "/thank-you",
        element: <ThankYou />,
    },
    {
        path: "/customer",
        element: <Customer></Customer>,
        children: [
            {
                path: "/customer/dashboard",
                element: (
                    <PrivateRoute>
                        <CustomerDashboard></CustomerDashboard>
                    </PrivateRoute>
                ),
            },
            {
                path: "/customer/profile",
                element: (
                    <PrivateRoute>
                        <CustomerProfile></CustomerProfile>
                    </PrivateRoute>
                ),
            },
        ],
    },
    {
        path: "/admin/login",
        element: <Login></Login>,
    },
    {
        path: "/admin",
        element: (
            <AdminRoute>
                <Main></Main>
            </AdminRoute>
        ),
        children: [
            {
                path: "/admin/kanaban",
                element: (
                    <AdminRoute>
                        <Kanaban></Kanaban>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-dashboard",
                element: (
                    <AdminRoute>
                        <NewDashboard />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-kanban",
                element: (
                    <AdminRoute>
                        <NewKanban />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-deal-request",
                element: (
                    <AdminRoute>
                        <RequestNewDeal />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-deal-action",
                element: (
                    <AdminRoute>
                        <NewDealActionPage />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-kanban/edit-deal/:id",
                element: (
                    <AdminRoute>
                        <EditSingleDeal />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-kanban/new-pitch/:id",
                element: (
                    <AdminRoute>
                        <NewPitch />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/new-kanban/underwritting/:id",
                element: (
                    <AdminRoute>
                        <NewUnderwritting />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/dashboard",
                element: (
                    <AdminRoute>
                        <Dashboard></Dashboard>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/staff-review",
                element: (
                    <AdminRoute>
                        <StaffReview />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/single-staff-review/:name",
                element: (
                    <AdminRoute>
                        <SingleStaffReview />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/weekly-appraisals/:name",
                element: (
                    <AdminRoute>
                        <WeeklyAppraisals />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/invoice-customer",
                element: (
                    <AdminRoute>
                        <InvoiceCustomers />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/invoice-customer/single-invoice/:email",
                element: (
                    <AdminRoute>
                        <SingleInvoiceCustomer />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/all-open-bank",
                element: (
                    <AdminRoute>
                        <OpenBankingList />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/callbacklist",
                element: (
                    <AdminRoute>
                        <CallBackList></CallBackList>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/kanaban/request-loan",
                element: (
                    <AdminRoute>
                        <RequestLoan></RequestLoan>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/add-single-deal",
                element: (
                    <AdminRoute>
                        <AddSingleDeal />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/kanaban/request-loan/mca",
                element: (
                    <AdminRoute>
                        <Mca></Mca>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/kanaban/request-loan/business-loan",
                element: (
                    <AdminRoute>
                        <BusinessLoan></BusinessLoan>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/kanaban/request-loan/invoice-finance",
                element: (
                    <AdminRoute>
                        <InvoiceFinance></InvoiceFinance>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/kanaban/request-loan/grants",
                element: (
                    <AdminRoute>
                        <Grants></Grants>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/dashboard/edit-loan/:id",
                element: (
                    <AdminRoute>
                        <EditLoanPage></EditLoanPage>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/dashboard/edit-loan/continue-to-pre-underwritting/:id",
                element: (
                    <AdminRoute>
                        <ContinueToPreUnderwriting></ContinueToPreUnderwriting>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/dashboard/edit-loan/underwritting/:id",
                element: (
                    <AdminRoute>
                        <Underwriting />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/user",
                element: (
                    <AdminRoute>
                        <User></User>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/add-user",
                element: (
                    <AdminRoute>
                        <AddUser></AddUser>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/user/edit-user/:uid",
                element: (
                    <AdminRoute>
                        <EditUser></EditUser>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/update-profile",
                element: (
                    <AdminRoute>
                        <UpdateUser></UpdateUser>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/funding-request",
                element: (
                    <AdminRoute>
                        <FundingRequest></FundingRequest>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/management/book",
                element: (
                    <AdminRoute>
                        <Book></Book>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/management/monthly",
                element: (
                    <AdminRoute>
                        <Monthly></Monthly>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/management/accounts",
                element: (
                    <AdminRoute>
                        <User></User>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/management/historic",
                element: (
                    <AdminRoute>
                        <Historic />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/management/roles",
                element: (
                    <AdminRoute>
                        <Roles />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/trash",
                element: (
                    <AdminRoute>
                        <Trash></Trash>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/calender",
                element: (
                    <AdminRoute>
                        <Calender></Calender>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/qa-bonus",
                element: (
                    <AdminRoute>
                        <QaBonus></QaBonus>
                    </AdminRoute>
                ),
            },
            // {
            //     path: '/admin/staff/qa-bonus',
            //     element: <AdminRoute><QaBonusStaff></QaBonusStaff></AdminRoute>
            // },
            {
                path: "/admin/cancelled-deals",
                element: (
                    <AdminRoute>
                        <CanceledDeals></CanceledDeals>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/company-info",
                element: (
                    <AdminRoute>
                        <CompanyInfoPage></CompanyInfoPage>
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/all-status",
                element: (
                    <AdminRoute>
                        <AllStatus />
                    </AdminRoute>
                ),
            },
            {
                path: "/admin/users/:id",
                element: (
                    <AdminRoute>
                        <UserManagement />
                    </AdminRoute>
                ),
            },
        ],
    },
    {
        path: "*",
        element: <ErrorPage></ErrorPage>,
    },
    // {
    //     path: '/team/login',
    //     element: <TeamLogin></TeamLogin>
    // },
    // {
    //     path: '/team',
    //     element: <TeamPrivateRoute><TeamLayout></TeamLayout></TeamPrivateRoute>,
    //     children: [
    //         {
    //             path: '/team/dashboard',
    //             element: <TeamPrivateRoute><Team></Team></TeamPrivateRoute>
    //         },
    //         {
    //             path: '/team/kanban',
    //             element: <TeamPrivateRoute><TeamKanban></TeamKanban></TeamPrivateRoute>
    //         },
    //         {
    //             path: '/team/dashboard/edit-loan/:id',
    //             element: <TeamPrivateRoute><EditLoanPage></EditLoanPage></TeamPrivateRoute>
    //         },
    //     ]

    // },
    // {
    //     path: '/shaun/login',
    //     element: <ShaunLogin></ShaunLogin>
    // },
    // {
    //     path: '/shaun',
    //     element: <ShaunPrivateRoute><ShaunLayout></ShaunLayout></ShaunPrivateRoute>,
    //     children: [
    //         {
    //             path: '/shaun/dashboard',
    //             element: <ShaunPrivateRoute><ShaunPrivatePage></ShaunPrivatePage></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban',
    //             element: <ShaunPrivateRoute><ShaunKanban></ShaunKanban></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban/request-loan',
    //             element: <ShaunPrivateRoute><RequestLoan></RequestLoan></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban/request-loan/mca',
    //             element: <ShaunPrivateRoute><Mca></Mca></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban/request-loan/business-loan',
    //             element: <ShaunPrivateRoute><BusinessLoan></BusinessLoan></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban/request-loan/invoice-finance',
    //             element: <ShaunPrivateRoute><InvoiceFinance></InvoiceFinance></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/kanban/request-loan/grants',
    //             element: <ShaunPrivateRoute><Grants></Grants></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/dashboard/edit-loan/:id',
    //             element: <ShaunPrivateRoute><EditLoanPage></EditLoanPage></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/update-profile',
    //             element: <ShaunPrivateRoute><UpdateUser></UpdateUser></ShaunPrivateRoute>
    //         },
    //         {
    //             path: '/shaun/calender',
    //             element: <ShaunPrivateRoute><Calender></Calender></ShaunPrivateRoute>
    //         },
    //     ]

    // },
    // {
    //     path: '/mcaloan/login',
    //     element: <McaLogin></McaLogin>
    // },
    // {
    //     path: '/mcaloan',
    //     element: <McaPrivateRoute><McaloanLayout></McaloanLayout></McaPrivateRoute>,
    //     children: [
    //         {
    //             path: '/mcaloan/kanban',
    //             element: <McaPrivateRoute><McaDashboard></McaDashboard></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/kanban/request-loan',
    //             element: <McaPrivateRoute><RequestLoan></RequestLoan></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/kanban/request-loan/mca',
    //             element: <McaPrivateRoute><Mca></Mca></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/kanban/request-loan/business-loan',
    //             element: <McaPrivateRoute><BusinessLoan></BusinessLoan></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/kanban/request-loan/invoice-finance',
    //             element: <McaPrivateRoute><InvoiceFinance></InvoiceFinance></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/kanban/request-loan/grants',
    //             element: <McaPrivateRoute><Grants></Grants></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/dashboard/edit-loan/:id',
    //             element: <McaPrivateRoute><EditLoanPage></EditLoanPage></McaPrivateRoute>
    //         },
    //         {
    //             path: '/mcaloan/calender',
    //             element: <McaPrivateRoute><Calender></Calender></McaPrivateRoute>
    //         },
    //     ]

    // },
    // {
    //     path: '/newbusiness/login',
    //     element: <NewBusinessLogin></NewBusinessLogin>
    // },
    // {
    //     path: '/newbusiness',
    //     element: <NewBusinessPrivateRoute><NewBusinessLayout></NewBusinessLayout></NewBusinessPrivateRoute>,
    //     children: [
    //         {
    //             path: '/newbusiness/kanban',
    //             element: <NewBusinessPrivateRoute><NewBusinessDashhboard></NewBusinessDashhboard></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/dashboard/edit-loan/:id',
    //             element: <NewBusinessPrivateRoute><EditLoanPage></EditLoanPage></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/kanban/request-loan',
    //             element: <NewBusinessPrivateRoute><RequestLoan></RequestLoan></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/kanban/request-loan/mca',
    //             element: <NewBusinessPrivateRoute><Mca></Mca></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/kanban/request-loan/business-loan',
    //             element: <NewBusinessPrivateRoute><BusinessLoan></BusinessLoan></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/kanban/request-loan/invoice-finance',
    //             element: <NewBusinessPrivateRoute><InvoiceFinance></InvoiceFinance></NewBusinessPrivateRoute>
    //         },
    //         {
    //             path: '/newbusiness/kanban/request-loan/grants',
    //             element: <NewBusinessPrivateRoute><Grants></Grants></NewBusinessPrivateRoute>
    //         },
    //     ]

    // },
]);

export { router };
