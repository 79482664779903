import React from "react";
import { useNavigate } from "react-router-dom";

const NewDealActionPage = () => {
    const navigate = useNavigate();

    const handleGetNewDeal = () => {
        // Logic to fetch a new deal
        console.log("Fetching a new deal...");
        navigate("/admin/new-deal-request"); // Redirect to the new deal page
    };

    const handleGoToKanban = () => {
        // Logic to navigate to the Kanban board
        console.log("Going to Kanban...");
        navigate("/admin/new-kanban"); // Redirect to the Kanban page
    };

    return (
        <div className="flex flex-col items-center justify-center mt-36">
            <h1 className="text-3xl font-bold mb-6">Looking for a New Deal?</h1>
            <div className="space-x-2">
                <button onClick={handleGetNewDeal} className="button">
                    Get a New Deal
                </button>
            </div>
            <p onClick={handleGoToKanban} className="mt-5 text-sm cursor-pointer">
                Go to Kanban
            </p>
        </div>
    );
};

export default NewDealActionPage;
