import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AgreementForm = () => {
    const [businessName, setBusinessName] = useState("");
    const [businessAddress, setBusinessAddress] = useState("");
    const [clientName, setClientName] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const details = { id, businessName, businessAddress, clientName };
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/sign-agreement`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
        });
        if (response.status === 201) {
            navigate(`/sign-document/${id}?${"clientName=" + clientName + "&" + "businessName=" + businessName + "&" + "businessAddress=" + businessAddress}`);
        } else {
            console.error("Failed to save details");
        }
    };

    return (
        <div data-theme="light" className="w-full h-screen flex justify-center items-center">
            <form onSubmit={handleSubmit} className="w-80">
                <label className="flex flex-col font-bold">
                    Client's Name:
                    <input className="input input-bordered input-sm mt-2 font-normal w-full max-w-xs" type="text" value={clientName} onChange={(e) => setClientName(e.target.value)} required />
                </label>
                <label className="flex flex-col font-bold mt-5">
                    Business Name:
                    <input className="input input-bordered input-sm mt-2 font-normal w-full max-w-xs" type="text" value={businessName} onChange={(e) => setBusinessName(e.target.value)} required />
                </label>
                <label className="flex flex-col font-bold mt-5">
                    Business Address:
                    <input className="input input-bordered input-sm mt-2 font-normal w-full max-w-xs" type="text" value={businessAddress} onChange={(e) => setBusinessAddress(e.target.value)} required />
                </label>

                <button className="block btn mt-5" type="submit">
                    Next
                </button>
            </form>
        </div>
    );
};

export default AgreementForm;
