import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import EditBook from "../../components/EditBook/EditBook";
import ViewDeal from "../../components/ViewDeal/ViewDeal";
import { NavLink, useNavigate } from "react-router-dom";

const Monthly = () => {
    const { adminData } = useContext(AuthContext);
    const [dealList, setDealList] = useState([]);
    const [totallRepayment, setTotallRepayment] = useState(0);
    const [refresh, setRefresh] = useState(true);
    const [viewDeal, setViewDeal] = useState({});
    const [sortByDateState, setSortByDateState] = useState(false);

    const navigate = useNavigate();

    const searchByMonthAndYear = (e) => {
        e.preventDefault();
        setDealList([]);
        const selectedMonth = e.target.month.value;
        const selectedYear = e.target.year.value;

        const url = `${process.env.REACT_APP_SERVER_URL}/deals/get-by-month-and-year/${selectedYear}/${selectedMonth}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setDealList(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/deals/GETALL`)
            .then((response) => response.json())
            .then((data) => {
                setDealList(data);
                console.log(data);
                let tempTotall = 0;
                data.map((item) => {
                    tempTotall = tempTotall + parseInt(item.repayment);
                });
                setTotallRepayment(tempTotall);
            })
            .catch((error) => {
                console.error("Error:", error);
                // Handle the error or display an error message
            });
    }, [refresh]);

    const sortDataByDate = () => {
        setSortByDateState(true);
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        });
        setDealList(sortedData);
    };
    const sortDataByDateRevarse = () => {
        setSortByDateState(false);
        const sortedData = [...dealList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        });
        setDealList(sortedData);
    };
    const rows = ["Funder", "Repayment", "Com Amo"];

    function separateByFunder(arr) {
        const funderArrays = {};

        arr.forEach((obj) => {
            const funder = obj.funder;

            if (!funderArrays[funder]) {
                funderArrays[funder] = [];
            }

            funderArrays[funder].push(obj);
        });

        return funderArrays;
    }

    function separateByCloser(arr) {
        const funderArrays = {};

        arr.forEach((obj) => {
            const closer = obj.closer;

            if (!funderArrays[closer]) {
                funderArrays[closer] = [];
            }

            funderArrays[closer].push(obj);
        });

        return funderArrays;
    }

    function getTotalRepayment(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return 0;
        }
        let totalRepayment = 0;

        arr.forEach((obj) => {
            const repayment = parseFloat(obj.repayment);
            if (!isNaN(repayment)) {
                totalRepayment += repayment;
            }
        });

        return totalRepayment;
    }

    function getTotalComamo(arr) {
        if (!Array.isArray(arr) || arr.length === 0) {
            return 0;
        }

        let totalComamo = 0;

        arr.forEach((obj) => {
            const comamo = parseFloat(obj.comamo);
            if (!isNaN(comamo)) {
                totalComamo += comamo;
            }
        });

        return totalComamo;
    }

    const separateByFunderresult = separateByFunder(dealList);
    const separateByCloserresult = separateByCloser(dealList);

    let totalRepaymentAcrossAll = 0;
    let totalComamoAcrossAll = 0;

    dealList.forEach((obj) => {
        const repayment = parseFloat(obj.repayment);
        const comamo = parseFloat(obj.comamo);

        if (!isNaN(repayment)) {
            totalRepaymentAcrossAll += repayment;
        }

        if (!isNaN(comamo)) {
            totalComamoAcrossAll += comamo;
        }
    });

    if (adminData?.role !== "super-admin") {
        navigate("/admin/new-kanban");
    }

    return (
        <div className="mr-10 pb-20">
            <div className="flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl mt-6">
                <NavLink to="/admin/management/book" className="btn hover:bg-primary hover:text-white">
                    Book
                </NavLink>
                <NavLink to="/admin/management/monthly" className="btn hover:bg-primary hover:text-white">
                    Monthly
                </NavLink>
                <NavLink to="/admin/management/accounts" className="btn hover:bg-primary hover:text-white">
                    Accounts
                </NavLink>
                <NavLink to="/admin/management/historic" className="btn hover:bg-primary hover:text-white">
                    Historic
                </NavLink>
                <NavLink to="/admin/management/roles" className="btn hover:bg-primary hover:text-white">
                    Roles
                </NavLink>
            </div>
            <div className="flex items-center mt-5">
                <form onSubmit={searchByMonthAndYear}>
                    <select name="month" className="input border-gray-300 mr-3 w-auto">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    <select name="year" className="input border-gray-300 mr-3 w-auto">
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                    </select>
                    <button type="submit" className="button">
                        Search
                    </button>
                </form>
            </div>
            <div className="flex gap-10">
                <div className="mt-5 basis-1/2">
                    <p className="mb-2 text-2xl">MCA:</p>
                    <div className="overflow-x-auto p-3 mb-5 rounded-lg bg-base-200">
                        <table className="table">
                            {/* head */}
                            <thead>
                                <tr>
                                    {rows.map((item, i) => (
                                        <th key={i} scope="col">
                                            {item === "Date Funded" ? (
                                                sortByDateState === false ? (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDate} className="cursor-pointer">
                                                            <BiSolidDownArrow />
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                            <BiSolidUpArrow />
                                                        </span>
                                                    </p>
                                                )
                                            ) : (
                                                item
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>365 Finance</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["365 Finance"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["365 Finance"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Liberis</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Liberis"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Liberis"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>YouLend</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="mb-2 text-2xl">Business Loans:</p>
                    <div className="overflow-x-auto p-3 rounded-lg bg-base-200">
                        <table className="table">
                            {/* head */}
                            <thead>
                                <tr>
                                    {rows.map((item, i) => (
                                        <th key={i} scope="col">
                                            {item === "Date Funded" ? (
                                                sortByDateState === false ? (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDate} className="cursor-pointer">
                                                            <BiSolidDownArrow />
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                            <BiSolidUpArrow />
                                                        </span>
                                                    </p>
                                                )
                                            ) : (
                                                item
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Capify</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Capify"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Capify"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Funder</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funder"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funder"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Sedge</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Sedge"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Sedge"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Swoop</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Seneca</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Seneca"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Seneca"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Maxcap</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Maxcap"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Maxcap"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Swiftfund</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Swiftfund"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Swiftfund"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Funding Circle</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funding Circle"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funding Circle"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Funding Alt</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["Funding Alt"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["Funding Alt"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>IWOCA</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByFunderresult["IWOCA"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByFunderresult["IWOCA"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                {/* <tr>
                                    <td className='font-bold'>
                                        Total
                                    </td>
                                    <td >
                                        £ {totalRepaymentAcrossAll.toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                    </td>
                                    <td >
                                        £ {totalComamoAcrossAll.toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mt-5 rounded-lg bg-base-200 basis-1/2">
                    <div className="overflow-x-auto p-3">
                        <table className="table">
                            {/* head */}
                            <thead>
                                <tr>
                                    {rows.map((item, i) => (
                                        <th key={i} scope="col">
                                            {item === "Date Funded" ? (
                                                sortByDateState === false ? (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDate} className="cursor-pointer">
                                                            <BiSolidDownArrow />
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <p className="flex justify-start items-center gap-2">
                                                        {item}{" "}
                                                        <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                            <BiSolidUpArrow />
                                                        </span>
                                                    </p>
                                                )
                                            ) : (
                                                item
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>wayne</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Wayne"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Wayne"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>jon</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Jon"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Jon"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>millie</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Milly"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Milly"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>smit</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Smit"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Smit"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>virgil</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Ver"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Ver"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Mark"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Mark"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>support</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["Swoop"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>new business</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>jay</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>lou</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>juniel</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>paul</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>josh</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>aidan</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["YouLend"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td>TBC</td>
                                    <td>£ {parseInt(getTotalRepayment(separateByCloserresult["TBC"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {parseInt(getTotalComamo(separateByCloserresult["TBC"])).toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                                <tr>
                                    <td className="font-bold">Totall</td>
                                    <td>£ {totalRepaymentAcrossAll.toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                    <td>£ {totalComamoAcrossAll.toLocaleString("en-US", { style: "decimal", useGrouping: true })}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Monthly;
