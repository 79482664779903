import React, { useEffect, useState } from "react";
import { getDownloadURL } from "firebase/storage";
import ViewLoan from "../../components/ViewLoan/ViewLoan";
import EditLoan from "../../components/EditLoan/EditLoan";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import LoanPdfList from "../../components/LoanPdfList/LoanPdfList";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { FaArrowRightLong } from "react-icons/fa6";

const NewDashboard = () => {
    const [loanList, setLoanList] = useState([]);
    const [viewLoan, setViewLoan] = useState({});
    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pdflist, setPdflist] = useState([]);
    const [sortByDateState, setSortByDateState] = useState(false);
    const [sortByFundedAmount, setSortByFundedAmount] = useState(false);
    const [sortByRequiredAmount, setSortByRequiredAmount] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(300);
    const [totalPages, setTotalPages] = useState(1);
    const [businessName, setBusinessName] = useState();
    const [subCategory, setSubCategory] = useState();
    const [source, setSource] = useState();
    const [businessAddress, setBusinessAddress] = useState();
    const [phone, setPhone] = useState();
    const [ownerName, setOwnerName] = useState();
    const [email, setEmail] = useState();
    const [fundingManager, setFundingManager] = useState();
    const [category, setCategory] = useState();

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
        setEditLoan({});
    };

    const handlePdfLinkClick = (e, item) => {
        e.preventDefault();

        getDownloadURL(item)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch((error) => {
                console.error("Error getting download URL:", error);
            });
    };

    const sortDataByDate = () => {
        setSortByDateState(true);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        });
        setLoanList(sortedData);
    };

    const sortDataByDateRevarse = () => {
        setSortByDateState(false);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        });
        setLoanList(sortedData);
    };

    const sortDataByFundedAmount = () => {
        setSortByFundedAmount(true);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        });
        setLoanList(sortedData);
    };
    const sortDataByFundedAmountReverse = () => {
        setSortByFundedAmount(false);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        });
        setLoanList(sortedData);
    };

    const sortDataByRequirements = () => {
        setSortByRequiredAmount(true);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        });
        setLoanList(sortedData);
    };
    const sortDataByRequirementsReverse = () => {
        setSortByRequiredAmount(false);
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        });
        setLoanList(sortedData);
    };

    const fetchData = (queryObj = {}, pageNumber) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/new-dashboard/all-deals`;

        const requestBody = JSON.stringify({
            queryObj,
            page: pageNumber,
            limit,
        });

        fetch(url, {
            method: "POST",
            body: requestBody,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTotalPages(data.totalPages);
                const sortedData = [...data.loans].sort((a, b) => {
                    const dateA = new Date(a.createdDate);
                    const dateB = new Date(b.createdDate);
                    return dateB - dateA;
                });
                setLoanList(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                alert("No New Data Found");
                setLoading(false);
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        fetchData({}, page);
    }, []);

    const handleSearch = () => {
        const queryObj = {};

        if (businessName) queryObj.businessName = businessName;
        if (subCategory) queryObj.subCategory = subCategory;
        if (source) queryObj.source = source;
        if (businessAddress) queryObj.businessAddress = businessAddress;
        if (phone) queryObj.phone = phone;
        if (ownerName) queryObj.ownerName = ownerName;
        if (email) queryObj.email = email;
        if (fundingManager) queryObj.fundingManager = fundingManager;
        if (category) queryObj.category = category;

        fetchData(queryObj);
    };

    const rows = ["Company Name", "Owner Name", "Phone", "Email", "Category", "F. Manager", "Aciton"];

    const handlePageChange = (newPage) => {
        console.log(newPage);
        setPage(newPage);
        fetchData({}, newPage);
    };

    if (loading) {
        return (
            <div className="text-4xl mt-[35vh] flex  justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center">
                <div>
                    <input type="text" className="input input-sm input-bordered text-sm mr-2 w-[120px]" placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    <input type="text" className="input input-sm input-bordered text-sm mr-2 w-[120px]" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <input type="text" className="input input-sm input-bordered text-sm mr-2 w-[120px]" placeholder="Owner Name" value={ownerName} onChange={(e) => setOwnerName(e.target.value)} />
                    <input type="text" className="input input-sm input-bordered text-sm mr-2 w-[120px]" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <select className="input input-sm input-bordered text-sm mr-2" onChange={(e) => setFundingManager(e.target.value)} value={fundingManager}>
                        <option>Select</option>
                        <option value="Wayne">Wayne</option>
                        <option value="Peter">Peter</option>
                        <option value="Smit">Smit</option>
                        <option value="Jon">Jon</option>
                        <option value="Millie">Millie</option>
                        <option value="Mark">Mark</option>
                        <option value="Becher">Becher</option>
                        <option value="Don">Don</option>
                        <option value="Carl">Carl</option>
                        <option value="Janne">Janne</option>
                        <option value="Charity">Charity</option>
                        <option value="Hena">Hena</option>
                        <option value="Steven">Steven</option>
                        <option value="ON_HOLD">ON_HOLD</option>
                    </select>
                    <select className="input input-sm input-bordered text-sm mr-2" onChange={(e) => setCategory(e.target.value)}>
                        <option>Category</option>
                        <option value="Restaurant">Restaurant</option>
                        <option value="Hardware">Hardware</option>
                        <option value="Footwear">Footwear</option>
                        <option value="Beer and Wine">Beer and Wine</option>
                        <option value="Car Parts">Car Parts</option>
                        <option value="Sports">Sports</option>
                        <option value="Convenient Store">Convenient Store</option>
                        <option value="Haulage">Haulage</option>
                        <option value="Retail">Retail</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="B2B Random">B2B Random</option>
                    </select>
                    <input type="text" className="input input-sm input-bordered text-sm mr-2" placeholder="Sub Category" value={subCategory} onChange={(e) => setSubCategory(e.target.value)} />
                    <select className="input input-sm input-bordered text-sm mr-2" onChange={(e) => setSource(e.target.value)}>
                        <option>Source</option>
                        <option value="Directories">Directories</option>
                        <option value="450k">450k</option>
                        <option value="B2B">B2B</option>
                        <option value="Creditsafe March 25">Creditsafe March 25</option>
                    </select>
                    <button onClick={handleSearch} className="btn bg-primary text-white btn-sm">
                        Search
                    </button>
                </div>

                <div>
                    <button onClick={() => (fetchData({}), setEmail(null), setOwnerName(null), setPhone(null), setBusinessName(null), setSubCategory(null), setFundingManager(null), setCategory(null))} className="btn bg-primary text-white btn-sm mr-3">
                        Clear Filter
                    </button>
                    <div className="join">
                        <button className="join-item btn btn-sm" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                            «
                        </button>
                        <button className="join-item btn btn-sm">{`Page ${page}`}</button>
                        <button className="join-item btn btn-sm" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                            »
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-20 rounded-lg bg-base-200">
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {rows.map((item, i) => (
                                    <th key={i} scope="col">
                                        {item === "Funded" && item !== "CREATED" ? (
                                            sortByFundedAmount === false ? (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByFundedAmount} className="cursor-pointer">
                                                        <BiSolidDownArrow />
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByFundedAmountReverse} className="cursor-pointer">
                                                        <BiSolidUpArrow />
                                                    </span>
                                                </p>
                                            )
                                        ) : (
                                            item !== "CREATED" && item !== "Req." && item
                                        )}

                                        {item === "CREATED" &&
                                            (sortByDateState === false ? (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByDate} className="cursor-pointer">
                                                        <BiSolidDownArrow />
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByDateRevarse} className="cursor-pointer">
                                                        <BiSolidUpArrow />
                                                    </span>
                                                </p>
                                            ))}

                                        {item === "Req." &&
                                            (sortByRequiredAmount === false ? (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByRequirements} className="cursor-pointer">
                                                        <BiSolidDownArrow />
                                                    </span>
                                                </p>
                                            ) : (
                                                <p className="flex justify-start items-center gap-2">
                                                    {item}{" "}
                                                    <span onClick={sortDataByRequirementsReverse} className="cursor-pointer">
                                                        <BiSolidUpArrow />
                                                    </span>
                                                </p>
                                            ))}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => (
                                    <tr key={i}>
                                        <td className="font-[500]">{item["business-name"]}</td>
                                        <td className="font-[500]">{item["business-owner-name"]}</td>
                                        <td className="font-[500]">{item["business-phone"]}</td>
                                        <td>{item["business-email"]}</td>
                                        <td>{item["category"] || "No Category"}</td>
                                        <td>{item["funding-manager"] || "Pending"}</td>
                                        <td>
                                            <Link className="btn text-xl px-7 bg-base-300" to={`/admin/new-kanban/edit-deal/${item?._id}`}>
                                                <FaArrowRightLong />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* popup */}
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoan item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
            <dialog id="loanPdfList" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[500px] max-w-[500px] p-10">
                    <LoanPdfList pdflist={pdflist} handlePdfLinkClick={handlePdfLinkClick} />
                    <button type="button" onClick={() => closeModal("loanPdfList")} className="mt-3 button !text-[#000] !bg-[#f2f2f2] !rounded">
                        Close
                    </button>
                </div>
            </dialog>
            <dialog id="viewLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <form method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <ViewLoan item={viewLoan} />
                    <div className="mt-3 text-right">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                    </div>
                </form>
            </dialog>
        </div>
    );
};

export default NewDashboard;
