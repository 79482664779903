import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import userplaceholder from "./../../assets/userplaceholder.png";

const Roles = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const accountsRows = ["Name", "Role", "Team", "Status", "Last Login", ""];

    const { adminData } = useContext(AuthContext);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/roles/GETALL`)
            .then((response) => response.json())
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    if (adminData?.role !== "super-admin") {
        navigate("/admin/new-kanban");
    }

    return (
        <div className="mt-5 mx-auto">
            <div className="flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl mt-6">
                <NavLink to="/admin/management/book" className="btn hover:bg-primary hover:text-white">
                    Book
                </NavLink>
                <NavLink to="/admin/management/monthly" className="btn hover:bg-primary hover:text-white">
                    Monthly
                </NavLink>
                <NavLink to="/admin/management/accounts" className="btn hover:bg-primary hover:text-white">
                    Current
                </NavLink>
                <NavLink to="/admin/management/historic" className="btn hover:bg-primary hover:text-white">
                    Historic
                </NavLink>
                <NavLink to="/admin/management/roles" className="btn hover:bg-primary hover:text-white">
                    Roles
                </NavLink>
            </div>
            <div className="mt-5 max-h-[calc(100vh-184px)] overflow-y-auto border border-gray-400 rounded-xl relative">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-base-100 border border-base-300">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 border">Action</th>
                                <th className="px-4 py-2 border">super-admin</th>
                                <th className="px-4 py-2 border">underwriter</th>
                                <th className="px-4 py-2 border">sales-manager</th>
                                <th className="px-4 py-2 border">asm</th>
                                <th className="px-4 py-2 border">staff</th>
                                <th className="px-4 py-2 border">affiliate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((row, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border">{row.Action}</td>
                                    <td className="px-4 py-2 border">{row["super-admin"]}</td>
                                    <td className="px-4 py-2 border">{row.underwriter}</td>
                                    <td className="px-4 py-2 border">{row["sales-manager"]}</td>
                                    <td className="px-4 py-2 border">{row.asm}</td>
                                    <td className="px-4 py-2 border">{row.staff}</td>
                                    <td className="px-4 py-2 border">{row.affiliate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Roles;
