import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FaPlus } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import userplaceholder from "./../../assets/userplaceholder.png";
import axios from "axios";

const User = () => {
    const [users, setUsers] = useState([]);
    const [holidayData, setHolidayData] = useState([]);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("current");

    const accountsRows = ["Name", "Role", "Team", "Status", "Last Login", ""];

    const { createAccount, updateUserInfo, adminData } = useContext(AuthContext);

    const register = (event) => {
        event.preventDefault();
        const userInfo = event.target;
        const displayName = userInfo.name.value;
        const email = userInfo.email.value;
        const pass = userInfo.pass.value;
        const dialog = document.getElementById("my_modal_1");

        createAccount(email, pass)
            .then((res) => {
                // console.log(res.user.uid);
                updateUserInfo({ displayName })
                    .then((response) => {
                        console.log(res.user.uid);
                        fetch(`${process.env.REACT_APP_SERVER_URL}/update-user`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ displayName, email, pass, createdDate: new Date().toISOString() }),
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                console.log("User updated:", result);
                                // Handle the response or perform any necessary actions
                                dialog.close();
                                setUsers([...users, { displayName, email, pass, createdDate: new Date().toISOString() }]);
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                                // Handle the error or display an error message
                            });
                    })
                    .catch((error) => {
                        // An error occurred
                        // ...
                    });
                // console.log(user)
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage);
            });
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/user/GETALL`)
            .then((response) => response.json())
            .then((data) => {
                // Call a function to handle the loan data
                setUsers(data);
            })
            .catch((error) => {
                console.error("Error:", error);
                // Handle the error or display an error message
            });
        // Fetch the holiday data using Axios
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/holidays`)
            .then((response) => {
                // Set the holidayData state with the fetched data
                setHolidayData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching holiday data:", error);
                // Handle the error, display a message if needed
            });
    }, []);

    if (adminData?.role !== "super-admin") {
        navigate("/admin/new-kanban");
    }

    return (
        <div className="mt-5 mx-auto">
            <div className="flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl mt-6">
                <NavLink to="/admin/management/book" className="btn hover:bg-primary hover:text-white">
                    Book
                </NavLink>
                <NavLink to="/admin/management/monthly" className="btn hover:bg-primary hover:text-white">
                    Monthly
                </NavLink>
                <NavLink to="/admin/management/accounts" className="btn hover:bg-primary hover:text-white">
                    Current
                </NavLink>
                <NavLink to="/admin/management/historic" className="btn hover:bg-primary hover:text-white">
                    Historic
                </NavLink>
                <NavLink to="/admin/management/roles" className="btn hover:bg-primary hover:text-white">
                    Roles
                </NavLink>
            </div>
            <div className="flex justify-between items-center mt-5">
                {/* <input onChange={(e) => searchByName(e)} name='search' type="text" placeholder="Search" className="input w-72" /> */}
                <p className="text-3xl font-semibold">Accounts</p>
                <div>
                    <button onClick={() => window.my_modal_1.showModal()} className="button">
                        <FaPlus />
                        Add
                    </button>
                </div>
            </div>
            <div className="mt-5 max-h-[calc(100vh-184px)] overflow-y-auto border border-gray-400 rounded-xl relative mb-20">
                <table className="table relative block ">
                    {/* head */}
                    <thead className="sticky top-0 bg-base-300">
                        <tr className="px-10">
                            {accountsRows.map((item, i) => (
                                <th key={i} scope="col" className="uppercase first:pl-10 py-5">
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, i) => (
                            <tr key={i}>
                                <th scope="row" className="pl-10 flex justify-start items-center gap-3">
                                    <img className="w-8 rounded-full" src={userplaceholder} alt="" />
                                    {item["displayName"]}
                                </th>
                                <td>{item["role"]}</td>
                                <td>{item["team"]}</td>
                                <td>{item["status"]}</td>
                                <td>
                                    {new Date(item?.createdDate).toLocaleDateString("en-UK", {
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                    })}
                                </td>
                                <td className="flex justify-center items-center gap-3">
                                    <button onClick={() => navigate(`/admin/users/${item?._id}`)} className="btn text-xl">
                                        <PiPencilSimpleDuotone />
                                    </button>
                                    <button className="btn">Disable Account</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <dialog id="my_modal_1" className="modal w-[400px] mx-auto rounded">
                <form onSubmit={register} method="dialog" className="modal-box">
                    <label className="label text-sm" htmlFor="introducedBy">
                        Introduced By:
                    </label>
                    <input id="introducedBy" className="w-full input input-sm input-bordered" type="text" />
                    <label className="label text-sm" htmlFor="name">
                        Name:
                    </label>
                    <input id="name" className="w-full input input-sm input-bordered" placeholder="Type User Name / Company Here" type="text" />
                    <label className="label text-sm" htmlFor="email">
                        Email:
                    </label>
                    <input type="email" id="email" className="w-full input input-sm input-bordered" placeholder="Type User Email" />
                    <label className="label text-sm" htmlFor="pass">
                        Password:
                    </label>
                    <input type="password" id="pass" className="w-full input input-sm input-bordered" placeholder="Type User Password" />
                    <div className="modal-action">
                        {/* if there is a button in form, it will close the modal */}
                        <button type="submit" className="btn btn-sm text-xs bg-rsf text-[#fff]">
                            Submit
                        </button>
                    </div>
                </form>
            </dialog>
        </div>
    );
};

export default User;
