import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const CustomerNavbar = () => {
    const { logOut } = useContext(AuthContext);
    return (
        <div style={{ boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05)" }} className="mx-5 px-5 p-3 mt-5 md:mr-10 md:ml-0 bg-[#fff] rounded-lg flex justify-between items-center ">
            <div>
                {/* <div className="text-sm breadcrumbs py-0">
                    <ul>
                        <li>Pages</li>
                        <li>Dashborad</li>
                    </ul>
                </div> */}
                <b className="text-[#344767]">Dashboard</b>
            </div>
            <div>
                <button onClick={logOut} className="button">
                    Logout
                </button>
            </div>
        </div>
    );
};

export default CustomerNavbar;
