import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowRightLong, FaPlus } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";

const SingleStaffReview = () => {
    const { user, adminData } = useContext(AuthContext);
    const { name } = useParams();
    const [addLiveAppraisals, setAddLiveAppraisals] = useState(false);
    const [monthlyStatistics, setMonthlyStatistics] = useState([]);
    const [callList, setCallList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [liveApprisalComment, setLiveApprisalComment] = useState(null);
    const [liveApprisalNote, setLiveApprisalNote] = useState(null);
    const [liveApprisalList, setLiveApprisalList] = useState([]);
    const [weeklyAppraisals, setWeeklyAppraisals] = useState([]);
    const [selectedAppraisal, setSelectedAppraisal] = useState(null);

    const allowedUsers = ["Steven", "Jon", "Smit", "Wayne", "Peter", "Sazzad"];

    const processDataByMonth = (data) => {
        const monthlyData = new Map();

        data.forEach((item) => {
            const date = new Date(item.createdDate);

            const monthYear = `${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;

            const repayment = parseFloat(item.repayment) || 0;

            if (!monthlyData.has(monthYear)) {
                monthlyData.set(monthYear, { count: 0, totalRepayment: 0 });
            }

            const monthInfo = monthlyData.get(monthYear);
            monthInfo.count += 1;
            monthInfo.totalRepayment += repayment;
            monthlyData.set(monthYear, monthInfo);
        });

        return Array.from(monthlyData, ([monthYear, { count, totalRepayment }]) => ({
            monthYear,
            count,
            totalRepayment,
        })).sort((a, b) => {
            const [aMonth, aYear] = a.monthYear.split("/").map(Number);
            const [bMonth, bYear] = b.monthYear.split("/").map(Number);
            return aYear === bYear ? bMonth - aMonth : bYear - aYear;
        });
    };

    const fetchSingleStaffStatistics = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v3/staff-statistics/${name || "undefined"}`);

            console.log(response.data);
            console.log(processDataByMonth(response.data));

            setMonthlyStatistics(processDataByMonth(response.data));
            fetchSingleCallDetails();
            fetchSingleLiveApprisal();
            fetchAppraisalsByName();
            setLoading(false);
        } catch (error) {
            fetchSingleCallDetails();
            fetchSingleLiveApprisal();
            fetchAppraisalsByName();
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    const fetchSingleCallDetails = async () => {
        try {
            const callListResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v3/calls/${name || "undefined"}`);

            setCallList(
                callListResponse.data
                    .map((item) => ({
                        ...item,
                        date: new Date(item.monthYear.split("/").reverse().join("-")), // Convert "MM/YYYY" to Date
                    }))
                    .sort((a, b) => b.date - a.date) // Sort by latest date first
                    .map(({ date, ...rest }) => rest) // Remove the date property after sorting
            );
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchSingleLiveApprisal = async () => {
        try {
            const liveApprisalsResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v3/live-apprisals/${name || "undefined"}`);
            setLiveApprisalList(liveApprisalsResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchAppraisalsByName = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/weekly-appraisals/${name}`);
            const data = await response.json();
            setWeeklyAppraisals(data);
        } catch (error) {
            console.error("Error fetching appraisals by name:", error);
        }
    };

    useEffect(() => {
        fetchSingleStaffStatistics();
    }, []);

    const handleLiveApprisalsSubmit = async () => {
        // console.log(selectedDate, selectedTime, dateTime);s
        try {
            setLoading(true);
            // Make API call here using axios
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/add-live_apprisals`,
                {
                    name,
                    date: new Date(),
                    comment: liveApprisalComment,
                    note: liveApprisalNote,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.data.acknowledged && response.data.insertedId) {
                alert("Live Apprisals Added Successfully");
                fetchSingleStaffStatistics();
            }
            console.log("Response:", response.data);
            // Handle response as needed
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        <PacmanLoader color="#e63a27" />;
    }

    if (adminData.role !== "super-admin" && user?.email !== "smit@readysteadyfinance.co.uk" && user?.email !== "jon@readysteadyfinance.co.uk" && user?.email !== "steven@sedgefunding.co.uk" && user.displayName !== name) {
        return <div className="text-4xl font-semibold text-center pt-36">You are not allowed to see this page</div>;
    }

    return (
        <div>
            <div className="grid grid-cols-2 mt-10 gap-10">
                <div>
                    <div>
                        <div className="flex justify-between items-center">
                            <p className="text-xl font-bold">Monthly Appraisals:</p>
                            {allowedUsers.includes(adminData?.name) && name !== adminData?.name && (
                                <Link className="btn bg-base-300 capitalize" to={`/admin/weekly-appraisals/${name}`}>
                                    Add New <FaPlus className="text-lg" />
                                </Link>
                            )}
                        </div>
                        <div className="overflow-x-auto border border-base-300 rounded-lg mt-3">
                            <table className="table table-zebra">
                                {/* head */}
                                <thead>
                                    <tr>
                                        <th>Review Date</th>
                                        <th>Overall Score</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {weeklyAppraisals.map((appraisal) => (
                                        <tr key={appraisal._id}>
                                            <td>{new Date(appraisal.date).toLocaleDateString("en-GB")}</td>
                                            <td>{appraisal.totalRating}</td>
                                            <td>
                                                <button
                                                    className="btn btn-sm text-xl bg-base-100"
                                                    onClick={() => {
                                                        setSelectedAppraisal(appraisal);
                                                        document.getElementById("my_modal_1").showModal();
                                                    }}>
                                                    <FaArrowRightLong />
                                                </button>
                                                {/* Modal */}
                                                {/* <dialog id="my_modal_1" className="modal">
                                                    <div className="modal-box">
                                                        {selectedAppraisal && (
                                                            <>
                                                                <h3 className="font-bold text-lg">Employee Details</h3>
                                                                <p><strong>Name:</strong> {selectedAppraisal.name}</p>
                                                                <p><strong>Overall Comments:</strong> {selectedAppraisal.overallComments}</p>
                                                                <p><strong>Total Rating:</strong> {selectedAppraisal.totalRating}</p>
                                                                <p><strong>Date:</strong> {new Date(selectedAppraisal.date).toLocaleDateString("en-GB")}</p>

                                                                <h4 className="font-bold mt-4">Ratings</h4>
                                                                <ul>
                                                                    {Object.entries(selectedAppraisal.ratings).map(([key, value]) => (
                                                                        <li key={key}><strong>{key}:</strong> {value}</li>
                                                                    ))}
                                                                </ul>

                                                                <h4 className="font-bold mt-4">Comments</h4>
                                                                <ul>
                                                                    {Object.entries(selectedAppraisal.comments).map(([key, value]) => (
                                                                        <li key={key}><strong>{key}:</strong> {value}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        )}

                                                        <div className="modal-action">
                                                            <form method="dialog">
                                                                <button className="btn">Close</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </dialog> */}
                                                <dialog id="my_modal_1" className="modal">
                                                    <div className="modal-box w-[800px] max-w-none p-10">
                                                        <form method="dialog">
                                                            <button className="btn btn-sm btn-circle text-white absolute right-5 top-5 text-xl bg-primary">✕</button>
                                                        </form>
                                                        {selectedAppraisal && (
                                                            <>
                                                                <h3 className="font-bold text-lg">Employee Details</h3>
                                                                <p>
                                                                    <strong>Name:</strong> {selectedAppraisal.name}
                                                                </p>
                                                                <p>
                                                                    <strong>Overall Comments:</strong> {selectedAppraisal.overallComments}
                                                                </p>
                                                                <p>
                                                                    <strong>Total Rating:</strong> {selectedAppraisal.totalRating}
                                                                </p>
                                                                <p>
                                                                    <strong>Date:</strong> {new Date(selectedAppraisal.date).toLocaleDateString("en-GB")}
                                                                </p>

                                                                {/* Ratings Section */}
                                                                <h4 className="font-bold my-4 text-xl">Ratings and Comments</h4>
                                                                <ul className="grid grid-cols-2 gap-5">
                                                                    {Object.keys(selectedAppraisal.ratings).map((key) => {
                                                                        const capitalizedKey = key
                                                                            .replace(/([A-Z])/g, " $1")
                                                                            .replace(/^./, (str) => str.toUpperCase())
                                                                            .trim();
                                                                        return (
                                                                            <li key={key} className="mb-2">
                                                                                <strong className="text-primary">{capitalizedKey}:</strong>
                                                                                <div>
                                                                                    <strong>Score:</strong> {selectedAppraisal.ratings[key]}
                                                                                </div>
                                                                                <div>
                                                                                    <strong>Comment:</strong> {selectedAppraisal.comments[key]}
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>
                                                </dialog>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="flex justify-between items-center">
                            <p className="text-xl font-bold">Live Appraisals:</p>
                            {addLiveAppraisals ? (
                                <button onClick={(e) => setAddLiveAppraisals(false)} className="btn bg-base-300 capitalize">
                                    Cancel
                                </button>
                            ) : (
                                allowedUsers.includes(adminData?.name) &&
                                name !== adminData?.name && (
                                    <button onClick={(e) => setAddLiveAppraisals(true)} className="btn bg-base-300 capitalize">
                                        Add New <FaPlus className="text-lg" />
                                    </button>
                                )
                            )}
                        </div>
                        {addLiveAppraisals && (
                            <div className="flex mt-2">
                                <input type="text" className="input-sm rounded-lg mr-3 grow" onChange={(e) => setLiveApprisalComment(e.target.value)} placeholder="Comment" />
                                <input type="text" className="input-sm rounded-lg mr-3" onChange={(e) => setLiveApprisalNote(e.target.value)} placeholder="Note" />
                                <button onClick={(e) => handleLiveApprisalsSubmit()} className="btn btn-sm bg-base-300">
                                    save
                                </button>
                            </div>
                        )}
                        <div className="overflow-x-auto border border-base-300 rounded-lg mt-3">
                            <table className="table table-zebra">
                                {/* head */}
                                <thead>
                                    <tr>
                                        <th>Review Date</th>
                                        <th>Comment</th>
                                        <th>Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {liveApprisalList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{new Date(item.date).toLocaleDateString("en-GB")}</td>
                                            <td>{item.comment}</td>
                                            <td>{item.note}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-between items-center">
                        <p className="text-3xl font-bold">Monthly Statistics:</p>
                    </div>
                    <div className="overflow-x-auto border border-base-300 rounded-lg mt-3">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Funded Amount</th>
                                    <th>Number Deals Funded</th>
                                    <th>Calls Made</th>
                                </tr>
                            </thead>
                            <tbody>
                                {callList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.monthYear}</td>
                                        <td>£ {monthlyStatistics.find((i) => i?.monthYear === item.monthYear)?.totalRepayment.toLocaleString() || 0}</td>
                                        {/* <td>£ {item.totalRepayment.toLocaleString()}</td> */}
                                        <td>{monthlyStatistics.find((i) => i?.monthYear === item.monthYear)?.count || 0}</td>
                                        <td>{item?.totalCalls.toLocaleString() || "N/A"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleStaffReview;
