import React, { useEffect, useState } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import Popup from "../../components/Popup/Popup";
import rslogo from "../../assets/rslogo-white.png";
import sedgeLogo from "../../assets/sedge-logo-white.png";
import { FaArrowRight } from "react-icons/fa6";

const ApplyForFunding = () => {
    const [step, setStep] = useState(1);
    const { width, height } = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { country, company } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const validCompanies = {
            uk: ["sedge-funding", "ready-steady-finance", "b2b-marketing"],
            us: ["sedge-funding", "ready-steady-finance", "b2b-marketing"],
        };

        if (!validCompanies[country] || !validCompanies[country].includes(company)) {
            console.log("Invalid country or company, redirecting to admin...");
            navigate("/404");
        }
    }, [country, company, navigate]);

    const versionBasedData = () => {
        if (country === "uk" && company === "sedge-funding") {
            return {
                logo: sedgeLogo,
                email: "info@sedgefunding.co.uk",
                footerText: "Sedge Funding",
                currency: "£",
            };
        } else if (country === "uk" && company === "ready-steady-finance") {
            return {
                logo: rslogo,
                email: " info@readysteadyfinance.co.uk",
                footerText: "Ready Steady Finance",
                currency: "£",
            };
        } else if (country === "us" && company === "sedge-funding") {
            return {
                logo: sedgeLogo,
                email: "info@sedgefunding.co.uk",
                footerText: "Sedge Funding",
                currency: "$",
            };
        } else if (country === "us" && company === "ready-steady-finance") {
            return {
                logo: rslogo,
                email: " info@readysteadyfinance.co.uk",
                footerText: "Ready Steady Finance",
                currency: "$",
            };
        } else if (country === "us" && company === "b2b-marketing") {
            return {
                logo: rslogo,
                email: " info@readysteadyfinance.co.uk",
                footerText: "Ready Steady Finance",
                currency: "$",
            };
        } else if (country === "uk" && company === "b2b-marketing") {
            return {
                logo: rslogo,
                email: " info@readysteadyfinance.co.uk",
                footerText: "Ready Steady Finance",
                currency: "£",
            };
        }
    };

    const [formData, setFormData] = useState({
        "required-amount": "",
        "funding-manager": "Peter",
        "loan-purpose": "",
        "business-type": "",
        "business-name": "",
        "lender-note": "",
        "avarage-monthly-turnover": "",
        takesCardPayments: "",
        "business-phone": "",
        "business-email": "",
        "business-owner-name": "",
        source: company + "-" + country,
        metadata: {
            createdAt: new Date().toISOString(),
            createdBy: "Peter",
        },
    });

    const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);

    const [step1, setStep1] = useState({
        "required-amount": "",
    });
    const [step2, setStep2] = useState({
        "loan-purpose": "",
    });
    const [step3, setStep3] = useState({
        "business-type": "",
    });
    const [step4, setStep4] = useState({
        "business-name": "",
    });
    const [step5, setStep5] = useState({
        "lender-note": "",
    });
    const [step6, setStep6] = useState({
        "avarage-monthly-turnover": "",
    });
    const [step7, setStep7] = useState({
        takesCardPayments: "",
    });
    const [step8, setStep8] = useState({
        "lender-note": "",
    });
    const [step9, setStep9] = useState({
        "business-phone": "",
        "business-email": "",
        "business-owner-name": "",
    });

    const nextStep = () => {
        setStep(step + 1);
        setAreAllFieldsFilled(false);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "") {
            parseInt((25 / 100) * formData["business-turnover"]);
        }
        setFormData({
            ...formData,
            [name]: value,
        });
        if (step === 1) {
            setStep1({
                ...step1,
                [name]: value,
            });
            setAreAllFieldsFilled(Object.values(step1).every((fieldValue) => fieldValue.trim() !== ""));
        }
        if (step === 2) {
            setStep2({
                ...step2,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step2,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 3) {
            setStep3({
                ...step3,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step3,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 4) {
            setStep4({
                ...step4,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step4,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 5) {
            setStep5({
                ...step5,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step5,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }

        if (step === 6) {
            setStep6({
                ...step6,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step6,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 7) {
            setStep7({
                ...step7,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step7,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 8) {
            setStep8({
                ...step8,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step8,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
        if (step === 9) {
            setStep9({
                ...step9,
                [name]: value,
            });
            setAreAllFieldsFilled(
                Object.values({
                    ...step9,
                    [name]: value,
                }).every((fieldValue) => fieldValue.trim() !== "")
            );
        }
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();

        const data = {
            lenders: {
                "New Deal": {
                    status: "Pre-Underwriting",
                    "show-in-kanban": "Yes",
                },
            },
            ...formData,
            "avarage-monthly-turnover": formData["avarage-monthly-turnover"] / 12,
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className={`min-h-screen`}>
            <div className="md:mt-0 bg-[#1c1c26] flex justify-between items-center px-20 py-6">
                <div>
                    <img src={versionBasedData()?.logo} className="w-[150px] " alt="" />
                </div>
                <div className="text-sm font-normal text-white">
                    <p>
                        Call:{" "}
                        <b className="text-primary">
                            <a href="tel:02045927799">020 4592 7799</a>
                        </b>
                    </p>
                    <p>
                        Email:{" "}
                        <b className="text-primary">
                            <a href={`mailto:${versionBasedData()?.email}`}>{versionBasedData()?.email}</a>
                        </b>
                    </p>
                </div>
            </div>
            <div className=" bg-[url(https://www.readysteadyfinance.co.uk/wp-content/uploads/2022/07/slider-1-1.png)] bg-no-repeat bg-cover bg-top min-h-[85vh] py-20 flex items-center pl-[20%]">
                <div className="flex items-center justify-center flex-col text-[#1c1c26] bg-white max-w-[600px] w-full p-10 rounded-2xl">
                    <form className={`md:max-w-[600px] w-full rounded-lg ${step === 1 ? "block" : "hidden"}`}>
                        {/* Step 1: Required Amount */}
                        <div>
                            <label htmlFor="required-amount" className="text-black block text-lg">
                                How much would you like to borrow?
                            </label>
                            <span className="text-sm">
                                ( Enter a value between {versionBasedData()?.currency}1,000 and {versionBasedData()?.currency}500,000 )
                            </span>
                            <input type="number" name="required-amount" id="required-amount" placeholder="Required Amount" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                        </div>
                        <div className="text-center">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>

                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 2 ? "block" : "hidden"}`}>
                        {/* Step 2: Additional Information */}
                        <div>
                            <div className="col-span-2">
                                <label className="text-black block text-lg">What is the finance for?</label>
                                <span className="text-sm mb-4 block">( Help us understand your requirements for the most suitable finance options )</span>
                                <div className="mt-2 grid grid-cols-2">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Urgent Bills" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                        <span className="ml-2">Urgent Bills</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Working Capital" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Working Capital</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Refinance" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Refinance</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Asset purchase" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Asset purchase</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Expanding business" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Expanding business</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Invoice Finance" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Invoice Finance</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="Just seeing what's available" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">Just seeing what's available</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="loan-purpose" value="I'm not sure" className="p-3 rounded-lg bg-base-300" onChange={handleInputChange} />
                                        <span className="ml-2">I'm not sure</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 3 ? "block" : "hidden"}`}>
                        {/* Step 3: Eligibility and Contact Information */}
                        <div className="col-span-2">
                            <label className="text-black block text-lg mb-4">What type of business do you own?</label>
                            <div className="mt-2 flex flex-wrap gap-y-3 gap-x-8">
                                <label className="inline-flex items-center">
                                    <input type="radio" name="business-type" value="Limited Company" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">Limited Company</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="business-type" value="Sole Trader" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">Sole Trader</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="business-type" value="Partnership" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">Partnership</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="business-type" value="Charity / Community interest company" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">Charity / Community interest company</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>

                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 4 ? "block" : "hidden"}`}>
                        {/* Step 4: Final Information */}
                        <div>
                            <div>
                                <label htmlFor="required-amount" className="text-black block text-lg">
                                    What is your business called?
                                </label>
                                <input type="text" name="business-name" id="business-name" placeholder="Business Name" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 5 ? "block" : "hidden"}`}>
                        {/* Step 4: Final Information */}
                        <div className="col-span-2">
                            <label className="text-black block text-lg mb-4">How long have you been trading?</label>
                            <div className="mt-2 grid grid-cols-2">
                                <label className="inline-flex items-center">
                                    <input type="radio" name="lender-note" value="Trading Time - Less than 3 months" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">Less than 3 months</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="lender-note" value="Trading Time - 3-12 months" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">3-12 months</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="lender-note" value="Trading Time - 1-2 years" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">1-2 years</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="lender-note" value="Trading Time - 2-3 years" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">2-3 years</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input type="radio" name="lender-note" value="Trading Time - More than 3 years" className="p-3 rounded-lg bg-slate-300" onChange={handleInputChange} />
                                    <span className="ml-2">More than 3 years</span>
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 6 ? "block" : "hidden"}`}>
                        {/* Step 6: Final Information */}
                        <div className="col-span-2">
                            <label htmlFor="avarage-monthly-turnover" className="text-black block text-lg">
                                What is your annual business turnover?
                            </label>
                            <input type="number" name="avarage-monthly-turnover" id="avarage-monthly-turnover" placeholder="Annual Turnover" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>

                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 7 ? "block" : "hidden"}`}>
                        {/* Step 7: Final Information */}
                        <div className="col-span-2">
                            <div className="col-span-2">
                                <label className="text-black block text-lg mb-4">Do you take card payments or online payments?</label>
                                <div className="mt-2">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="takesCardPayments" value="yes" className="form-radio" onChange={handleInputChange} />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input type="radio" name="takesCardPayments" value="no" className="form-radio" onChange={handleInputChange} />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>

                            {formData.takesCardPayments === "yes" && <input type="number" name="marchent-turnover" placeholder="Money taken on cards" className="p-3 rounded-lg bg-slate-200 w-full text-black" required onChange={handleInputChange} />}
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 8 ? "block" : "hidden"}`}>
                        {/* Step 8: Final Information */}
                        <div className="col-span-2">
                            <p className="mb-2 text-lg font-semibold text-left text-[#000000]">Based on the information provided you are eligible to the following -</p>
                            <ul>
                                <li>
                                    <b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Long term loan 2-5 years - ${versionBasedData()?.currency}${parseInt(2 * (formData["avarage-monthly-turnover"] / 2)).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>{" "}
                                </li>
                                <li>
                                    <b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Short term loan 6m - 2 Years -  ${versionBasedData()?.currency}${parseInt(1.5 * (formData["avarage-monthly-turnover"] / 2)).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
                                </li>
                                <li>
                                    <b>{formData?.takesCardPayments === "yes" && `MCA -  ${versionBasedData()?.currency}${parseInt((150 / 100) * formData["marchent-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
                                </li>
                                <li>
                                    <b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && formData["loan-purpose"] === "Invoice Finance" && `Invoice finance -  ${versionBasedData()?.currency}${parseInt((40 / 100) * (formData["avarage-monthly-turnover"] / 2)).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
                                </li>
                                <li>
                                    <b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Stock Finance  -  ${versionBasedData()?.currency}${parseInt((80 / 100) * (formData["avarage-monthly-turnover"] / 2)).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>{" "}
                                </li>
                            </ul>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button type="button" className={`btn bg-primary mt-3 text-white border-none px-10`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <form onSubmit={handleSubmit} className={`md:max-w-[600px] w-full rounded-lg ${step === 9 ? "block" : "hidden"}`}>
                        {/* Step 9: Final Information */}
                        <div>
                            <label htmlFor="business-owner-name" className="text-black block">
                                Full Name
                            </label>
                            <input type="text" name="business-owner-name" id="business-owner-name" placeholder="Type Here" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                        </div>
                        <div className="mt-2">
                            <label htmlFor="business-phone" className="text-black block">
                                Contact Number
                            </label>
                            <input type="text" name="business-phone" id="business-phone" placeholder="Type Here" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                        </div>
                        <div className="mt-2">
                            <label htmlFor="business-email" className="text-black block">
                                E-mail Address
                            </label>
                            <input type="text" name="business-email" id="business-email" placeholder="Type Here" className="p-3 rounded-lg bg-slate-200 w-full text-black mt-2" autoComplete="off" required onChange={handleInputChange} />
                        </div>
                        <div className="text-center">
                            <button type="submit" className={`btn bg-primary mt-3 text-white border-none px-10 ${!areAllFieldsFilled && "btn-disabled"}`} aria-disabled="true" onClick={nextStep}>
                                Next <FaArrowRight />
                            </button>
                        </div>
                    </form>
                    <div className={`md:max-w-[600px] w-full z-[10] rounded-lg ${step === 10 ? "block" : "hidden"}`}>
                        {/* Step 4: Final Information */}
                        <h1 className="text-3xl font-black text-center text-[#e63a27]">Thank you for submitting your application</h1>
                        <p className="mt-3 text-center">One of our funding managers will be in contact shortly to discuss your application.</p>
                        <p className="mt-5 font-bold text-center text-[#e63a27]">Login details have been sent to your e-mail so you can view live progress on your applications.</p>
                    </div>
                    {step === 10 && <Confetti numberOfPieces={150} recycle={true} width={width} height={height} />}
                </div>
            </div>
            <div className="md:mt-0 bg-[#1c1c26] flex justify-center items-center px-20 py-6">
                <p className="text-sm text-white">
                    Copyright ©2025 All rights reserved | This a product of <b className="text-white"> {versionBasedData()?.footerText}</b>
                </p>
            </div>
        </div>
    );
};

export default ApplyForFunding;
