import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPhone, FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { PiEnvelopeSimpleBold } from "react-icons/pi";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { DataContext } from "../../context/DataContext";
import KanbanStatuscolumSkeleton from "../KanbanStatuscolumSkeleton/KanbanStatuscolumSkeleton";

const statusArray = ["Initial Review", "Additional Documents", "The Great Takeover", "Pre-Underwriting", "Pre-Offer", "Underwriting", "Extra docs ob", "Awaiting Offer", "Offer", "Contract Sent", "Awaiting Funding", "Funded This Month", "Last Chance", "Cancelled", "Cancelled for ever"];

const KanbanStatusColumn = ({ status, setLoanList, loanList, metaData, setMetaData, reduceTotalLoansNumber, increaseTotalLoansNumber }) => {
	const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
	const { searchRequestValue, setUploading } = useContext(DataContext);
	const { user, adminData } = useContext(AuthContext);
	const [dataLoading, setDataLoading] = useState(true);
	const [page, setPage] = useState(1);

	const dragStartHandler = (e, i) => {
		e.dataTransfer.setData("id", i?._id);
		e.dataTransfer.setData("status", i?.status);
		reduceTotalLoansNumber(status);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const dragDropHandler = async (e) => {
		const dealId = e.dataTransfer.getData("id");
		const prevStatus = e.dataTransfer.getData("status");

		if (adminData?.role !== "super-admin" && user?.email !== "millie@sedgefunding.co.uk" && user.email === "hena@readysteadyfinance.co.uk" && user?.email !== "smit@readysteadyfinance.co.uk" && statusArray.indexOf(prevStatus) > statusArray.indexOf(status)) {
			return;
		}

		setUploading(true);

		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/update/loan-request`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				body: JSON.stringify({
					email: user.email,
					data: { status: status },
					_id: dealId,
				}),
			});

			if (response.ok) {
				await fetchMetaData();
				await setLoanList((prevList) => prevList.map((item) => (item._id === dealId ? { ...item, status } : item)));
				await increaseTotalLoansNumber(status);
				setUploading(false);
			} else {
				console.error("Error:", response.status);
				setUploading(false);
			}
		} catch (error) {
			console.error("Error:", error);
			setUploading(false);
		}
	};

	const getStatusClass = (status) => {
		switch (status) {
			case "Initial Review":
				return "status-initial-review";
			case "Additional Documents":
				return "status-additional-documents";
			case "The Great Takeover":
				return "status-the-great-takeover";
			case "Pre-Underwriting":
				return "status-pre-underwriting";
			case "Pre-Offer":
				return "status-pre-offer";
			case "Underwriting":
				return "status-underwriting";
			case "Extra docs ob":
				return "status-extra-docs-ob";
			case "Awaiting Offer":
				return "status-awaiting-offer";
			case "Offer":
				return "status-offer";
			case "Contract Sent":
				return "status-contract-sent";
			case "Awaiting Funding":
				return "status-awaiting-funding";
			case "Funded This Month":
				return "status-funded-this-month";
			case "Funded":
				return "status-funded";
			case "Last Chance":
				return "last-chance";
			case "Cancelled":
				return "status-cancelled";
			case "Cancelled for ever":
				return "status-cancelled-for-ever";
			default:
				return ""; // Default class if status is not found
		}
	};

	const statusClass = getStatusClass(status);

	const copyText = (text) => {
		navigator.clipboard.writeText(text);
	};

	// test
	const loaderRef = useRef(null);

	const fetchData = () => {
		try {
			axios
				.post(
					`${serverURL}/api/v2/loan-requests/${status}/${page}`,
					{
						email: user.email,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				)
				.then((response) => {
					const data = response.data;
					if (Array.isArray(data)) {
						// setLoanList(prevData => [...prevData, ...data]);
						setLoanList((prevData) => {
							// Create a map of _id to object for quick lookup
							const prevDataMap = new Map(prevData.map((item) => [item._id, item]));

							// Iterate over new data to add or replace objects
							data.forEach((item) => {
								prevDataMap.set(item._id, item);
							});

							// Convert the map back to an array
							return Array.from(prevDataMap.values());
						});
					} else {
						console.error("Response data is not an array:", data);
					}
					setDataLoading(false);
				})
				.catch((error) => {
					setDataLoading(false);
					console.error("Error fetching initial data:", error);
				});
		} catch (error) {
			setDataLoading(false);
			console.error("Error fetching initial data:", error);
		}
	};

	const fetchMetaData = async () => {
		try {
			const statsResponse = await axios.post(
				`${serverURL}/api/v2/loan-requests-stats/${status}`,
				{
					email: user.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);

			const statsData = statsResponse.data;
			setMetaData((prevData) => [...prevData, { status, totalLoans: statsData.total, totalRequiredAmount: statsData.totalRequiredAmount }]);
		} catch (error) {
			console.error("Error fetching loan stats:", error);
		}
	};

	const fetchMoreData = async () => {
		try {
			const response = await axios.post(
				`${serverURL}/api/v2/loan-requests/${status}/${page + 1}`,
				{
					email: user.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);

			const newData = response.data;
			setLoanList((prevLoanList) => [...prevLoanList, ...newData]);
			setPage((prevPage) => prevPage + 1);
		} catch (error) {
			console.error("Error fetching more data:", error);
		}
	};

	const fetchSearchedData = async () => {
		setLoanList([]);
		try {
			const response = await axios.post(
				`${serverURL}/api/v2/loan-requests-search/${status}`,
				{
					email: user.email,
					search: searchRequestValue,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);

			const newData = response.data;
			// setLoanList([...newData]);
			setLoanList((prevData) => {
				// Create a map of _id to object for quick lookup
				const prevDataMap = new Map(prevData.map((item) => [item._id, item]));

				// Iterate over new data to add or replace objects
				newData.forEach((item) => {
					prevDataMap.set(item._id, item);
				});

				// Convert the map back to an array
				return Array.from(prevDataMap.values());
			});
			setDataLoading(false);
		} catch (error) {
			console.error("Error fetching more data:", error);
			setDataLoading(false);
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const target = entries[0];
			if (target.isIntersecting) {
				fetchMoreData(); // Assuming fetchMoreData function is implemented to fetch more data
			}
		});

		if (loaderRef.current) {
			observer.observe(loaderRef.current);
		}

		return () => {
			if (loaderRef.current) {
				observer.unobserve(loaderRef.current);
			}
		};
	}, [fetchMoreData]); // Ensure 'page' is included in dependencies

	useEffect(() => {
		if (Object.keys(searchRequestValue).length !== 0 && searchRequestValue.constructor === Object) {
			fetchSearchedData();
		}
	}, [searchRequestValue]);

	useEffect(() => {
		if (Object.keys(searchRequestValue).length === 0) {
			fetchData();
		}
		fetchMetaData();
	}, []);

	if (dataLoading) {
		return <KanbanStatuscolumSkeleton />;
	}

	return (
		<div className="min-w-[300px] h-[calc(100vh-200px)] flex flex-col pl-3 border-l border-dashed border-[#b7b7b7]">
			<div className={`arrow-box arrow--1 py-3 ${statusClass} text-white`}>
				{/* {status} - {loanList.filter(item => item.status === status).length} */}
				{status} ({loanList.filter((item) => item.status === status).length}) - {metaData.find((i) => i?.status === status)?.totalLoans}
			</div>
			<div className="text-center text-4xl font-light text-gray-400 py-5 flex items-center justify-center">
				{/* £{calculateTotalSum(loanList.filter(item => item.status === status))} */}£ {metaData.find((i) => i?.status === status)?.totalRequiredAmount.toLocaleString("en-US")}
			</div>
			<div onDrop={(e) => adminData?.team !== "call-center" && dragDropHandler(e)} onDragOver={(e) => adminData?.team !== "call-center" && handleDragOver(e)} className="flex min-h-20 flex-grow flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto">
				{loanList
					.filter((item) => item.status === status)
					.map((i, index) => (
						<div key={index} className={`rounded-lg bg-base-100 shadow-sm w-full ${i["updated"] === true && i["seenby"] && !i["seenby"].includes(user.email) ? "border-[3px] border-primary" : "border-[1px] border-base"} ${i["updated"] === true && !i["seenby"] && "border-[3px] border-primary "}`}>
							<Link draggable onDragStart={(e) => adminData?.team !== "call-center" && dragStartHandler(e, i)} className="p-4 inline-block w-full rounded-b-none rounded-t-lg" to={`/admin/dashboard/edit-loan/${i?._id}`}>
								<h3 className="text-sm mb-3 font-bold">{i["business-name"]}</h3>
								<p className="text-sm">Require: {i["country"] + ((i["required-amount"] && parseInt(i["required-amount"]).toLocaleString("en-US")) || 0)}</p>
								<p className="text-sm">Funded: {i["country"] + ((i["funded-amount"] && parseInt(i["funded-amount"]).toLocaleString("en-US")) || 0)}</p>
								<div className="flex flex-row items-center mt-2">
									<span className="text-gray-300 rounded-full text-xl mr-3">
										<FaUserCircle />
									</span>
									<span className="text-xs text-gray-500">{i["funding-manager"] || "Pending"}</span>
								</div>
								<div className="flex justify-between">
									{adminData?.team !== "call-center" && <p className="text-xs text-gray-500 mt-2">{i["type"]}</p>}
									<p className="text-xs text-gray-500 mt-2">
										{new Date(i?.createdDate).toLocaleDateString("en-UK", {
											day: "numeric",
											month: "numeric",
											year: "numeric",
										})}
									</p>
								</div>
								{i?.callBack && (
									<div className="text-center text-white bg-red-700 py-2 mt-3 rounded-lg">
										<p className="text-xs">{new Date(i?.callBack?.date).toLocaleDateString("en-UK", { day: "numeric", month: "numeric", year: "numeric" }) + " , " + i?.callBack?.time}</p>
									</div>
								)}
							</Link>
							<div className="grid grid-cols-2 text-sm p-2 border-t">
								{i["business-phone"] && (
									<button className="flex justify-center items-center border-r py-1" onClick={() => copyText(i["business-phone"])}>
										<FaPhone />
									</button>
								)}
								{i["business-email"] && (
									<button className="flex justify-center items-center text-xl" onClick={() => copyText(i["business-email"])}>
										<PiEnvelopeSimpleBold />
									</button>
								)}
							</div>
						</div>
					))}
				{Object.keys(searchRequestValue).length === 0 && searchRequestValue.constructor === Object && loanList.filter((item) => item.status === status).length < metaData.find((i) => i?.status === status)?.totalLoans && (
					<div ref={loaderRef} className="text-4xl my-3 text-centers">
						<MoonLoader color="#e63a27" size={33} className="mx-auto" />
					</div>
				)}
			</div>
		</div>
	);
};

export default KanbanStatusColumn;
