import React, { useContext, useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { AuthContext } from "../../context/AuthContext";
import { PacmanLoader } from "react-spinners";
import axios from "axios";
import { Link } from "react-router-dom";

const StaffReview = () => {
    const { adminData, user } = useContext(AuthContext);
    const [thisMonthFundedData, setThisMonthFundedData] = useState([]);
    const [qaSummeryData, setQaSummeryData] = useState([]);
    const [lastQaSummeryData, setLastQaSummeryData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allMonthlyApprisals, setAllMonthlyApprisals] = useState([]);
    const employeeList = {
        "super-admin": ["Jon", "Smit", "Mark", "Becher", "Carl", "Don", "Janne", "Charity", "Steven", "Hena"],
        "jon@readysteadyfinance.co.uk": ["Don", "Becher", "Janne"],
        "don@sedgefunding.co.uk": ["Becher", "Janne"],
        "steven@sedgefunding.co.uk": ["Smit", "Carl", "Charity"],
        "smit@readysteadyfinance.co.uk": ["Carl", "Charity"],
    };

    const getTotalCallsByEmployee = (employee) => {
        return qaSummeryData.filter((entry) => entry.employee === employee).reduce((total, entry) => total + entry.calls, 0);
    };
    const getLastMonthTotalCallsByEmployee = (employee) => {
        return lastQaSummeryData.filter((entry) => entry.employee === employee).reduce((total, entry) => total + entry.calls, 0);
    };

    const previousMonthDate = new Date();
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

    function getTotalRatingByMonth(data, name, date) {
        const targetMonth = date.getMonth();
        const targetYear = date.getFullYear();

        return data.reduce((total, entry) => {
            const entryDate = new Date(entry.date);
            if (entry.name === name && entryDate.getMonth() === targetMonth && entryDate.getFullYear() === targetYear) {
                total += entry.totalRating;
            }
            return total;
        }, 0);
    }

    const fetchAppraisalsByName = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/monthly-appraisals/all`);
            const data = await response.json();
            setAllMonthlyApprisals(data);
        } catch (error) {
            console.error("Error fetching appraisals by name:", error);
        }
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/qa-report`)
            .then((response) => response.json())
            .then((data) => {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();

                console.log(currentMonth - 1);

                const monthlySummeryData = data.filter((entry) => {
                    const dateObject = new Date(entry.date);
                    return dateObject.getMonth() === currentMonth && dateObject.getFullYear() === currentYear;
                });

                const lastMonthlySummeryData = data.filter((entry) => {
                    const dateObject = new Date(entry.date);
                    return dateObject.getMonth() === currentMonth - 1 && dateObject.getFullYear() === currentYear;
                });

                setQaSummeryData(monthlySummeryData);
                setLastQaSummeryData(lastMonthlySummeryData);
                fetchAppraisalsByName();
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    // Function to fetch data from API
    const fetchFundingData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v3/book/funding-stats`);

            // Function to get the first and last date of a given month
            const getMonthRange = (year, month) => {
                const start = new Date(year, month - 1, 1); // Start of the month
                const end = new Date(year, month, 0, 23, 59, 59, 999); // End of the month
                return { start, end };
            };

            // Get current and last month
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1; // Convert to 1-based month
            const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
            const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

            // Get date ranges for filtering
            const { start: startThisMonth, end: endThisMonth } = getMonthRange(currentYear, currentMonth);
            const { start: startLastMonth, end: endLastMonth } = getMonthRange(lastMonthYear, lastMonth);

            // Function to convert various date formats to JavaScript Date object
            const parseDate = (date) => new Date(typeof date === "object" && date.$date ? date.$date : date);

            // Function to filter and calculate totals for each closer
            const calculateTotals = (data) => {
                const totals = {};

                data.forEach((deal) => {
                    const dealDate = parseDate(deal.createdDate);
                    const repayment = parseFloat(deal.repayment) || 0;
                    const closer = deal.closer || "Unknown";

                    if (!totals[closer]) {
                        totals[closer] = { thisMonth: 0, lastMonth: 0 };
                    }

                    if (dealDate >= startThisMonth && dealDate <= endThisMonth) {
                        totals[closer].thisMonth += repayment;
                    } else if (dealDate >= startLastMonth && dealDate <= endLastMonth) {
                        totals[closer].lastMonth += repayment;
                    }
                });

                return totals;
            };

            const result = calculateTotals(response.data);

            console.log(result);

            setThisMonthFundedData(result);
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect to fetch data on component mount
    useEffect(() => {
        fetchFundingData();
    }, []);

    const permissionBasedUserList = () => {
        if (adminData?.role === "super-admin") {
            return employeeList["super-admin"];
        } else if (user?.email === "smit@readysteadyfinance.co.uk") {
            return employeeList["smit@readysteadyfinance.co.uk"];
        } else if (user?.email === "jon@readysteadyfinance.co.uk") {
            return employeeList["jon@readysteadyfinance.co.uk"];
        } else if (user?.email === "steven@sedgefunding.co.uk") {
            return employeeList["steven@sedgefunding.co.uk"];
        } else if (user?.email === "don@sedgefunding.co.uk") {
            return employeeList["don@sedgefunding.co.uk"];
        }
    };

    if (isLoading) {
        return (
            <div className="font-bold text-4xl flex h-screen justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div>
            <div className="overflow-x-auto border border-base-300 mt-10 p-5 rounded-lg">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>Staff</th>
                            <th>Previous Score</th>
                            <th>Latest Score</th>
                            <th>Funded last Month</th>
                            <th>Funded this Month</th>
                            <th>Calls Last Month</th>
                            <th>Calls This Month</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissionBasedUserList().map((eachEmployee) => (
                            <tr>
                                <td>{eachEmployee}</td>
                                <td>{getTotalRatingByMonth(allMonthlyApprisals, eachEmployee, previousMonthDate)}</td>
                                <td>{getTotalRatingByMonth(allMonthlyApprisals, eachEmployee, new Date())}</td>
                                {/* <td>£ 0</td> */}
                                {/* <td>£ {thisMonthFundedData.find((item) => item.closer === eachEmployee) ? thisMonthFundedData.find((item) => item.closer === eachEmployee).totalFunded.toLocaleString() : 0}</td> */}
                                <td>£ {Math.round(thisMonthFundedData?.[eachEmployee]?.lastMonth || 0).toLocaleString()}</td>
                                <td>£ {Math.round(thisMonthFundedData?.[eachEmployee]?.thisMonth || 0).toLocaleString()}</td>
                                {/* <td>{thisMonthFundedData.find((item) => item.closer === eachEmployee) ? thisMonthFundedData.find((item) => item.closer === eachEmployee).totalFundedDeals : 0}</td> */}
                                <td>{getLastMonthTotalCallsByEmployee(eachEmployee).toLocaleString()}</td>
                                <td>{getTotalCallsByEmployee(eachEmployee).toLocaleString()}</td>
                                <td>
                                    <Link to={`/admin/single-staff-review/${eachEmployee}`} className="btn text-xl px-7 bg-base-300">
                                        <FaArrowRightLong />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StaffReview;
