import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";

function Navbar() {
    const { logOut, user, adminData } = useContext(AuthContext);
    const { fundedThisMonth, staffComs } = useContext(DataContext);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
    };

    useEffect(() => {
        const htmlElement = document.getElementsByTagName("html")[0];
        if (htmlElement) {
            htmlElement.setAttribute("data-theme", theme);
        }
    }, [theme]);

    return (
        <div className="px-5 w-full max-w-full p-3 bg-base-200 rounded-lg flex justify-between items-center h-[60px] sticky top-0 z-10">
            <div className="flex gap-5 items-center">
                <p className="text-rsp font-semibold">{currentTime.toLocaleTimeString("en-GB", { timeZone: "Europe/London", hour: "numeric", minute: "numeric", hour12: true })}</p>
                <b className="text-rsp">Dashboard</b>
            </div>
            {user?.email !== "max@swiftfund.co.uk" && (
                <div className="flex gap-5 items-center">
                    <p>Funded This Month : £ {fundedThisMonth}</p>
                    {user?.email !== "hena@readysteadyfinance.co.uk" && <p>Coms This Month : £ {Math.round(parseFloat(staffComs)).toLocaleString()}</p>}
                </div>
            )}
            <div className="flex gap-5 items-center">
                <h2 className="text-lg font-semibold">{user?.displayName || "Profile Not Updated"}</h2>
                <button onClick={logOut} className="button">
                    Logout
                </button>
                <label className="flex cursor-pointer gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="5" />
                        <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
                    </svg>
                    <input onClick={toggleTheme} type="checkbox" value="synthwave" className="toggle theme-controller" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                    </svg>
                </label>
            </div>
        </div>
    );
}

export default Navbar;
