import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import { PiPencilSimpleDuotone } from "react-icons/pi";
import userplaceholder from "./../../assets/userplaceholder.png";

const Historic = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const accountsRows = ["Name", "Role", "Team", "Status", "Last Login", ""];

    const { adminData } = useContext(AuthContext);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/historic/GETALL`)
            .then((response) => response.json())
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    if (adminData?.role !== "super-admin") {
        navigate("/admin/new-kanban");
    }

    return (
        <div className="mt-5 mx-auto">
            <div className="flex gap-3 flex-wrap bg-base-300 p-5 rounded-xl mt-6">
                <NavLink to="/admin/management/book" className="btn hover:bg-primary hover:text-white">
                    Book
                </NavLink>
                <NavLink to="/admin/management/monthly" className="btn hover:bg-primary hover:text-white">
                    Monthly
                </NavLink>
                <NavLink to="/admin/management/accounts" className="btn hover:bg-primary hover:text-white">
                    Current
                </NavLink>
                <NavLink to="/admin/management/historic" className="btn hover:bg-primary hover:text-white">
                    Historic
                </NavLink>
                <NavLink to="/admin/management/roles" className="btn hover:bg-primary hover:text-white">
                    Roles
                </NavLink>
            </div>
            <div className="mt-5 max-h-[calc(100vh-184px)] overflow-y-auto border border-gray-400 rounded-xl relative">
                <table className="table relative block ">
                    {/* head */}
                    <thead className="sticky top-0 bg-base-300">
                        <tr className="px-10">
                            {accountsRows.map((item, i) => (
                                <th key={i} scope="col" className="uppercase first:pl-10 py-5">
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, i) => (
                            <tr key={i}>
                                <th scope="row" className="pl-10 flex justify-start items-center gap-3">
                                    <img className="w-8 rounded-full" src={userplaceholder} alt="" />
                                    {item["displayName"]}
                                </th>
                                <td>{item["role"]}</td>
                                <td>{item["team"]}</td>
                                <td>{item["status"]}</td>
                                <td>
                                    {new Date(item?.createdDate).toLocaleDateString("en-UK", {
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                    })}
                                </td>
                                <td className="flex justify-center items-center gap-3">
                                    <button onClick={() => navigate(`/admin/users/${item?._id}`)} className="btn text-xl">
                                        <PiPencilSimpleDuotone />
                                    </button>
                                    <button className="btn">Disable Account</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Historic;
