import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { PacmanLoader } from "react-spinners";
import axios from "axios";

const NewUnderwritting = () => {
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loanData, setLoanData] = useState({});

    // data from form
    const [type, setType] = useState();
    const [marchentTurnover, setMerchentTurnover] = useState();
    const [monthlyTurnover, setMonthlyTurnover] = useState();
    const [endOfDayBalance, setEndOfDayBalance] = useState();
    const [currentFundedLender, setCurrentFundedLender] = useState([]);
    const [stockFinance, setStockFinance] = useState(false);
    const [invoiceFinance, setInvoiceFinance] = useState(false);

    const newUpdateFunction = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token");

            const conditions = [
                { condition: marchentTurnover >= 1500 && !currentFundedLender.includes("YouLend"), newType: "YouLend" },
                { condition: marchentTurnover >= 10000 && !currentFundedLender.includes("365"), newType: "365" },
                { condition: monthlyTurnover >= 20000 && !currentFundedLender.includes("Swiftfund") && currentFundedLender.includes("Other"), newType: "Swiftfund" },
                // { condition: ["Ltd", "LLP", "PLC"].includes(type) && monthlyTurnover >= 1000 && !currentFundedLender.includes("IWOCA"), newType: "IWOCA" },
                { condition: ["Ltd", "LLP", "PLC"].includes(type) && monthlyTurnover >= 5000 && !currentFundedLender.includes("Funding Circle"), newType: "Funding Circle" },
                { condition: monthlyTurnover >= 5000 && endOfDayBalance >= 500 && !currentFundedLender.includes("Capify"), newType: "Capify" },
                { condition: ["Ltd", "LLP", "PLC"].includes(type) && monthlyTurnover >= 30000 && !currentFundedLender.includes("Funding Alt"), newType: "Funding Alt" },
                { condition: monthlyTurnover >= 15000 && !currentFundedLender.includes("Maxcap"), newType: "Maxcap" },
                { condition: ["Ltd", "LLP", "PLC"].includes(type) && monthlyTurnover >= 12000 && !currentFundedLender.includes("Bizcap"), newType: "Bizcap" },
                { condition: ["Sole Trader", "Partnership"].includes(type) && monthlyTurnover >= 50000 && !currentFundedLender.includes("Bizcap"), newType: "Bizcap" },
                // { condition: ["Ltd"].includes(type) && monthlyTurnover >= 40000 && !currentFundedLender.includes("Playter") && ((currentFundedLender.includes("Other") && currentFundedLender.length < 5) || (!currentFundedLender.includes("Other") && currentFundedLender.length < 4)), newType: "Playter" },
                { condition: stockFinance === true && !currentFundedLender.includes("Multifi") && monthlyTurnover >= 500, newType: "Multifi" },
                { condition: stockFinance === true && !currentFundedLender.includes("Lenkie") && monthlyTurnover >= 100000, newType: "Lenkie" },
                { condition: !currentFundedLender.includes("Sapi") && marchentTurnover >= 500, newType: "Sapi" },
                { condition: invoiceFinance === true && !currentFundedLender.includes("Penny"), newType: "Penny" },
                { condition: invoiceFinance === true && !currentFundedLender.includes("Penny") && monthlyTurnover >= 25000, newType: "Bibby" },
                { condition: !currentFundedLender.includes("Sedge MCA") && marchentTurnover >= 5000, newType: "Sedge MCA" },
            ];

            for (const { condition, newType } of conditions) {
                if (condition) {
                    const data = {
                        id: loanData?._id, // You need to have this deal ID from somewhere (e.g., state, props)
                        lenderName: newType,
                        lenderData: { status: "Underwriting", "show-in-kanban": "Yes" },
                        additionalUpdates: {
                            "avarage-monthly-turnover": monthlyTurnover,
                            "marchent-turnover": marchentTurnover,
                            "business-type": type,
                            fundedLender: [...currentFundedLender], // Assuming you want to add the new lender to the array
                        },
                    };

                    const updateResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/underwriting-update`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    });
                }
            }

            const data = {
                lender: "New Deal",
                "show-in-kanban": "No",
            };
            const response = await axios.put(
                `${process.env.REACT_APP_SERVER_URL}/api/v3/update/loan-request/${loanData?._id}`,
                {
                    email: user?.email,
                    data,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            fetchDataFunction(); // Assuming this refetches updated data
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDataFunction = () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/single-deal/${id}`;
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ email: user?.email }),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoanData(data);
                console.log(data);

                setType(data["business-type"]);
                setCurrentFundedLender(data.fundedLender || []);

                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchDataFunction();
    }, [id, user?.email]);

    const handleChange = (e) => {
        const value = e.target.value;

        // If the checkbox is checked, add the value to the array
        if (e.target.checked) {
            setCurrentFundedLender([...currentFundedLender, value]);
        }
        // If the checkbox is unchecked, remove the value from the array
        else {
            setCurrentFundedLender(currentFundedLender.filter((item) => item !== value));
        }
    };

    const handleOtherLoanChange = (e) => {
        const value = e.target.value;

        // If the checkbox is checked, add the value to the array
        if (e.target.value && e.target.value !== "false") {
            setCurrentFundedLender([...currentFundedLender, value]);
        }
        // If the checkbox is unchecked, remove the value from the array
        else {
            setCurrentFundedLender(currentFundedLender.filter((item) => item !== "Other"));
        }
    };

    if (loading) {
        return (
            <div className="font-bold text-4xl flex h-screen justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div>
            <div className="flex justify-between items-center mt-5">
                <p className="btn" onClick={(e) => navigate(-1)}>
                    Go To Edit Page
                </p>
                <button type="button" onClick={newUpdateFunction} className="button">
                    submit
                </button>
            </div>
            <div className="flex gap-10 mb-20">
                <div className="basis-1/3">
                    <p className="text-xl font-semibold mt-5 mb-4">Underwriting for {loanData["business-name"]}</p>
                    <div className="">
                        <label htmlFor="businessType" className="text-sm mb-1 block">
                            Business Type:
                        </label>
                        <select onChange={(e) => setType(e.target.value)} name="business-type" id="businessType" className="p-3 rounded-lg bg-base-300 w-full" required>
                            <option defaultValue value={loanData["business-type"]}>
                                {loanData["business-type"] || "Select Type"}
                            </option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                    </div>
                    <div className="my-8">
                        <label className="text-sm mb-1 block">
                            Avarage Monthly Turnover: <span className="text-primary"> Current Value: {loanData["business-turnover"] || 0}</span>
                        </label>
                        <input onChange={(e) => setMonthlyTurnover(parseFloat(e.target.value))} type="text" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>
                    <div className="my-8">
                        <label className="text-sm mb-1 block">
                            Merchant Turnover: <span className="text-primary"> Current Value: {loanData["marchent-turnover"] || 0}</span>
                        </label>
                        <input onChange={(e) => setMerchentTurnover(parseFloat(e.target.value))} type="text" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>

                    <div className="my-8">
                        <label className="text-sm mb-1 block">End of Day Balance:</label>
                        <input onChange={(e) => setEndOfDayBalance(parseFloat(e.target.value))} type="text" className="p-3 rounded-lg bg-base-300 w-full" required />
                    </div>

                    <div className="mt-5">
                        <p className="label-text text-lg">Does the customer have any other lending?</p>

                        <div className="flex gap-6">
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">Yes</span>
                                <input type="radio" name="Other" value={"Other"} onChange={handleOtherLoanChange} defaultChecked={currentFundedLender.includes("Other")} className="radio" />
                            </label>
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">No</span>
                                <input type="radio" name="Other" onChange={handleOtherLoanChange} value={false} className="radio" />
                            </label>
                        </div>
                    </div>

                    <div className="mt-5">
                        <p className="label-text text-lg">Are they looking for finance for stock?</p>

                        <div className="flex gap-6">
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">Yes</span>
                                <input type="radio" name="stockFinance" onChange={(e) => setStockFinance(true)} className="radio" />
                            </label>
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">No</span>
                                <input type="radio" name="stockFinance" onChange={(e) => setStockFinance(false)} className="radio" />
                            </label>
                        </div>
                    </div>
                    <div className="mt-5">
                        <p className="label-text text-lg">Are they interested in invoice finance?</p>

                        <div className="flex gap-6">
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">Yes</span>
                                <input type="radio" name="invoiceFinance" onChange={(e) => setInvoiceFinance(true)} className="radio" />
                            </label>
                            <label className="label cursor-pointer justify-start gap-4">
                                <span className="label-text text-lg">No</span>
                                <input type="radio" name="invoiceFinance" onChange={(e) => setInvoiceFinance(false)} className="radio" />
                            </label>
                        </div>
                    </div>

                    <p className="font-bold mb-3 mt-5">Click Checkbox If Already Took Loan -</p>
                    <div className="flex gap-10">
                        <div className="w-[145px]">
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">365</span>
                                <input onChange={handleChange} value={"365"} type="checkbox" checked={currentFundedLender.includes("365")} className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">YouLend</span>
                                <input onChange={handleChange} value={"YouLend"} checked={currentFundedLender.includes("YouLend")} type="checkbox" className="checkbox" />
                            </label>
                            {/* <label className="label cursor-pointer">
                                <span className="label-text text-lg">IWOCA</span>
                                <input checked={currentFundedLender.includes("IWOCA")} onChange={handleChange} value={"IWOCA"} type="checkbox" className="checkbox" />
                            </label> */}
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Swiftfund</span>
                                <input checked={currentFundedLender.includes("Swiftfund")} onChange={handleChange} value={"Swiftfund"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Lenkie</span>
                                <input checked={currentFundedLender.includes("Lenkie")} onChange={handleChange} value={"Lenkie"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Bibby</span>
                                <input checked={currentFundedLender.includes("Bibby")} onChange={handleChange} value={"Bibby"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Sedge MCA</span>
                                <input checked={currentFundedLender.includes("Sedge MCA")} onChange={handleChange} value={"Sedge MCA"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Capify</span>
                                <input checked={currentFundedLender.includes("Capify")} onChange={handleChange} value={"Capify"} type="checkbox" className="checkbox" />
                            </label>
                        </div>
                        <div className="w-[220px]">
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Funding Circle</span>
                                <input checked={currentFundedLender.includes("Funding Circle")} onChange={handleChange} value={"Funding Circle"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Funding Alternative</span>
                                <input checked={currentFundedLender.includes("Funding Alt")} onChange={handleChange} value={"Funding Alt"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Maxcap</span>
                                <input checked={currentFundedLender.includes("Maxcap")} onChange={handleChange} value={"Maxcap"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Bizcap</span>
                                <input checked={currentFundedLender.includes("Bizcap")} onChange={handleChange} value={"Bizcap"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Sapi</span>
                                <input checked={currentFundedLender.includes("Sapi")} onChange={handleChange} value={"Sapi"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Multifi</span>
                                <input checked={currentFundedLender.includes("Multifi")} onChange={handleChange} value={"Multifi"} type="checkbox" className="checkbox" />
                            </label>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">Penny</span>
                                <input checked={currentFundedLender.includes("Penny")} onChange={handleChange} value={"Penny"} type="checkbox" className="checkbox" />
                            </label>
                        </div>
                    </div>
                    {/* <div className="mt-5">
                        <button onClick={() => setViewLenders(true)} className='button mt-5 mb-10'>See Available Lender <FaArrowRightLong className='ml-3' /></button>
                    </div> */}
                </div>
                <div className="mb-20 basis-2/3">
                    <p className="text-xl font-semibold mt-5 mb-10">Full list of Results</p>
                    <div className="overflow-x-auto rounded-lg mt-5">
                        <table className="table border border-base-300">
                            {/* head */}
                            <thead>
                                <tr className="bg-base-300 rounded-t-lg">
                                    <th>Lender</th>
                                    <th>Needed</th>
                                    <th>Portal or E-mail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* row 1 */}
                                {marchentTurnover >= 1500 && !currentFundedLender.includes("YouLend") && (
                                    <tr className="hover">
                                        <td>YouLend</td>
                                        <td>6 month or O/B</td>
                                        <td>https://youlend.com/dashboard/partners/leads</td>
                                    </tr>
                                )}
                                {/* row 2 */}
                                {marchentTurnover >= 10000 && !currentFundedLender.includes("365") && (
                                    <tr className="hover">
                                        <td>365 Finance</td>
                                        <td>6 month or O/B</td>
                                        <td>https://365businessfinance.my.site.com/partner/s/</td>
                                    </tr>
                                )}
                                {/* row 3 */}
                                {monthlyTurnover >= 20000 && !currentFundedLender.includes("Swiftfund") && currentFundedLender.includes("Other") && (
                                    <tr className="hover">
                                        <td>Swiftfund</td>
                                        <td>6 month</td>
                                        <td>max@swiftfund.co.uk</td>
                                    </tr>
                                )}
                                {/* row 4 */}

                                {/* {(type === "Ltd" || type === "LLP" || type === "PLC") && monthlyTurnover >= 1000 && !currentFundedLender.includes("IWOCA") && (
                                    <tr className="hover">
                                        <td>IWOCA</td>
                                        <td>Follow Steps</td>
                                        <td>https://www.iwoca.co.uk/introducer/portal/</td>
                                    </tr>
                                )} */}

                                {/* row 5 */}
                                {(type === "Ltd" || type === "LLP" || type === "PLC") && monthlyTurnover >= 5000 && !currentFundedLender.includes("Funding Circle") && (
                                    <tr className="hover">
                                        <td>Funding Circle</td>
                                        <td>Follow Steps</td>
                                        <td>https://www.fundingcircle.com/uk/auth/login</td>
                                    </tr>
                                )}

                                {/* row 6 */}
                                {(type === "Ltd" || type === "LLP" || type === "PLC") && monthlyTurnover >= 30000 && !currentFundedLender.includes("Funding Alt") && (
                                    <tr className="hover">
                                        <td>Funding Alternative</td>
                                        <td>Open Banking</td>
                                        <td>mel.marriott@fundingalternative.co.uk</td>
                                    </tr>
                                )}

                                {/* row 7 */}
                                {monthlyTurnover >= 15000 && !currentFundedLender.includes("Maxcap") && (
                                    <tr className="hover">
                                        <td>Maxcap</td>
                                        <td>6 month</td>
                                        <td>samp@maxcap.co.uk</td>
                                    </tr>
                                )}

                                {/* row 8 */}
                                {(type === "Ltd" || type === "LLP" || type === "PLC") && monthlyTurnover >= 12000 && !currentFundedLender.includes("Bizcap") && (
                                    <tr className="hover">
                                        <td>Bizcap</td>
                                        <td>6 month</td>
                                        <td>TBC</td>
                                    </tr>
                                )}

                                {/* row 9 */}
                                {(type === "Sole Trader" || type === "Partnership") && monthlyTurnover >= 50000 && !currentFundedLender.includes("Bizcap") && (
                                    <tr className="hover">
                                        <td>Bizcap</td>
                                        <td>6 month</td>
                                        <td>TBC</td>
                                    </tr>
                                )}

                                {/* row 9 */}
                                {monthlyTurnover >= 5000 && endOfDayBalance >= 500 && !currentFundedLender.includes("Capify") && (
                                    <tr className="hover">
                                        <td>Capify</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 11 */}
                                {monthlyTurnover >= 500 && !currentFundedLender.includes("Multifi") && stockFinance === true && (
                                    <tr className="hover">
                                        <td>Multifi</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 12 */}
                                {monthlyTurnover >= 100000 && !currentFundedLender.includes("Lenkie") && stockFinance === true && (
                                    <tr className="hover">
                                        <td>Lenkie</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 13 */}
                                {marchentTurnover >= 500 && !currentFundedLender.includes("Sapi") && (
                                    <tr className="hover">
                                        <td>Sapi</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 14 */}
                                {invoiceFinance === true && !currentFundedLender.includes("Penny") && (
                                    <tr className="hover">
                                        <td>Penny</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 15 */}
                                {invoiceFinance === true && monthlyTurnover >= 25000 && !currentFundedLender.includes("Bibby") && (
                                    <tr className="hover">
                                        <td>Bibby</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}

                                {/* row 16 */}
                                {marchentTurnover >= 5000 && !currentFundedLender.includes("Sedge MCA") && (
                                    <tr className="hover">
                                        <td>Sedge MCA</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUnderwritting;
