import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { PacmanLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";

const QaBonus = () => {
    const { user, adminData } = useContext(AuthContext);
    const employeeList = {
        "super-admin": ["Jon", "Smit", "Mark", "Becher", "Carl", "Don", "Janne", "Charity", "Steven", "Hena"],
        "jon@readysteadyfinance.co.uk": ["Jon", "Don", "Becher", "Janne"],
        "don@sedgefunding.co.uk": ["Don", "Becher", "Janne"],
        "steven@sedgefunding.co.uk": ["Steven", "Smit", "Carl", "Charity"],
        "smit@readysteadyfinance.co.uk": ["Smit", "Carl", "Charity"],
    };
    const [renderCount, setRenderCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const currentDate = new Date();
    const [monthlyData, setMonthlyData] = useState([]);
    const [monthlySummerySelectedEmplyee, setMonthlySummerySelectedEmplyee] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [editQAReport, setEditQAReport] = useState(false);
    const filterbyMonth = currentDate.getMonth();

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = (f) => {
        f(false);
    };

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [employeeFilter, setEmployeeFilter] = useState("");

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleEmployeeFilterChange = (e) => {
        setEmployeeFilter(e.target.value);
    };

    const sortedData = monthlyData.sort((a, b) => new Date(b.date) - new Date(a.date));

    const filteredData = sortedData.filter((row) => {
        if (fromDate && new Date(row.date) < new Date(fromDate)) {
            return false;
        }
        if (toDate && new Date(row.date) > new Date(toDate)) {
            return false;
        }
        if (employeeFilter && row.employee.toLowerCase() !== employeeFilter.toLowerCase()) {
            return false;
        }
        return true;
    });

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/qa-report`)
            .then((response) => response.json())
            .then((data) => {
                setMonthlyData(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, [renderCount, filterbyMonth]);

    const handleAddData = (e) => {
        e.preventDefault();
        const data = {
            date: e.target.date.value,
            employee: e.target.employee.value,
            calls: parseInt(e.target.calls.value),
        };
        axios
            .post(`${process.env.REACT_APP_SERVER_URL}/qa/weekly-data`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setRenderCount((prevCount) => prevCount + 1);
                setShowPopup(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleEditData = (e) => {
        e.preventDefault();
        const data = {
            sales: e.target.sales.value,
        };

        axios
            .post(`${process.env.REACT_APP_SERVER_URL}/qaMonthlySummery/${monthlySummerySelectedEmplyee}/${filterbyMonth}`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setRenderCount((prevCount) => prevCount + 1);
                setEditQAReport(false);
                setMonthlySummerySelectedEmplyee("");
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        e.target.reset();
    };

    const permissionBasedUserList = () => {
        if (adminData?.role === "super-admin") {
            return employeeList["super-admin"];
        } else if (user?.email === "smit@readysteadyfinance.co.uk") {
            return employeeList["smit@readysteadyfinance.co.uk"];
        } else if (user?.email === "jon@readysteadyfinance.co.uk") {
            return employeeList["jon@readysteadyfinance.co.uk"];
        } else if (user?.email === "steven@sedgefunding.co.uk") {
            return employeeList["steven@sedgefunding.co.uk"];
        } else if (user?.email === "don@sedgefunding.co.uk") {
            return employeeList["don@sedgefunding.co.uk"];
        }
    };

    if (isLoading) {
        return (
            <div className="font-bold text-4xl flex h-screen justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className="mt-5">
            <div className="flex gap-4 mb-5">
                <div className="grow">
                    <div className="pl-0 pb-2 flex justify-end gap-5 ">
                        <div>
                            <input type="date" value={fromDate} onChange={handleFromDateChange} className="input input-sm bg-base-200" />
                        </div>
                        <div>
                            <input type="date" value={toDate} onChange={handleToDateChange} className="input input-sm bg-base-200" />
                        </div>

                        <div>
                            <select value={employeeFilter} onChange={handleEmployeeFilterChange} className="input input-sm bg-base-200">
                                <option value="">Select an option</option>
                                {permissionBasedUserList().map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            {adminData?.role === "super-admin" && (
                                <button onClick={openPopup} className="btn btn-sm bg-primary text-white text-xs">
                                    Add New Data
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="border border-base-300 rounded-lg text-sm h-[83vh] overflow-auto">
                        <div className="grid grid-cols-4 bg-base-200 divide-x divide-base-300">
                            <span className="p-3">No.</span>
                            <span className="p-3">Date</span>
                            <span className="p-3">Name</span>
                            <span className="p-3">Calls</span>
                        </div>
                        <div className="divide-y divide-base-300">
                            {filteredData
                                .filter((item) => permissionBasedUserList()?.includes(item.employee)) // Correct filtering
                                .map((row, i) => (
                                    <div key={i} className="grid grid-cols-4 divide-x divide-base-300 hover:bg-base-200 cursor-pointer">
                                        <span className="p-3">{i + 1}</span>
                                        <span className="p-3">
                                            {new Date(row.date).toLocaleDateString("en-GB", {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                            })}
                                        </span>
                                        <span className="p-3">{row.employee}</span>
                                        <span className="p-3">{row.calls}</span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
                    <form onSubmit={(e) => handleAddData(e)} className="bg-base-200 p-8 rounded-xl w-[650px] shadow-md">
                        <h2 className="text-lg font-semibold">Add New Datas</h2>
                        <div className="grid grid-cols-3 gap-x-2">
                            <div>
                                <label htmlFor="month" className="label pb-0 mt-1">
                                    Date:
                                </label>
                                <input type="date" id="date" name="date" className="input w-full" required />
                            </div>
                            <div>
                                <label htmlFor="employee" className="label pb-0 mt-1">
                                    Employee:
                                </label>
                                <select name="employee" className="input w-full" required>
                                    <option value="Jon">Jon</option>
                                    <option value="Smit">Smit</option>
                                    <option value="Mark">Mark</option>
                                    <option value="Becher">Becher</option>
                                    <option value="Don">Don</option>
                                    <option value="Carl">Carl</option>
                                    <option value="Janne">Janne</option>
                                    <option value="Charity">Charity</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="jon" className="label pb-0 mt-1">
                                    Calls:
                                </label>
                                <input type="number" id="calls" name="calls" defaultValue={0} className="input w-full" required />
                            </div>
                        </div>
                        <div className="flex justify-between mt-5">
                            <button type="submit" className="mr-2 button">
                                Submit
                            </button>
                            <button type="button" onClick={() => closePopup(setShowPopup)} className="button !bg-gray-400">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {editQAReport && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
                    <form onSubmit={(e) => handleEditData(e)} className="bg-base-200 p-8 rounded-xl w-[650px] shadow-md">
                        <h2 className="text-lg font-semibold">Edit Summery:</h2>
                        <div>
                            <div>
                                <label htmlFor="sales" className="label pb-0 mt-1">
                                    Sales:
                                </label>
                                <input type="number" id="sales" name="sales" defaultValue={0} className="input w-full" />
                            </div>
                            {/* <div>
                                <label htmlFor="renew" className='label pb-0 mt-1'>Renew:</label>
                                <input
                                    type="number"
                                    id="renew"
                                    name="renew"
                                    defaultValue={0}
                                    className="input w-full"
                                />
                            </div> */}
                        </div>
                        <div className="flex justify-between mt-5">
                            <button type="submit" className="mr-2 button">
                                Submit
                            </button>
                            <button type="button" onClick={() => closePopup(setEditQAReport)} className="button !bg-gray-400">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default QaBonus;
