import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const Agreement1 = ({ businessName, businessAddress, clientName, signature }) => {
    Font.register({
        family: 'Poppins',
        src: 'https://fonts.gstatic.com/s/poppins/v1/TDTjCH39JjVycIF24TlO-Q.ttf'
    });

    const styles = StyleSheet.create({
        page: {
            padding: 50,
            fontSize: 10,
            fontFamily: 'Poppins'
        },
        header: {
            fontSize: 12,
            marginBottom: 7,
            fontWeight: 'black',
        },
        section: {
            marginBottom: 10,
        },
        paragraph: {
            marginBottom: 5,
        },
        recitals: {
            marginBottom: 5,
            textAlign: 'justify',
        },
        signeture: {
            width: 100,
            height: 'auto'
        },
    });

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.header}>Business Grants Consultancy Agreement</Text>
                    <Text style={styles.paragraph}>
                        This Business Grants Consultancy Agreement ("Agreement") is made and entered into as of this ("Effective Date"), by and between:
                    </Text>
                    <Text style={styles.paragraph}>Consultant:</Text>
                    <Text style={styles.paragraph}>Sedge Funding Ltd</Text>
                    <Text style={styles.paragraph}>124-128, City Road, London, England, EC1V 2NJ</Text>
                    <Text style={styles.paragraph}>Client:</Text>
                    <Text style={styles.paragraph}>{businessName}</Text>
                    <Text style={styles.paragraph}>{businessAddress}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>Recitals</Text>
                    <Text style={styles.recitals}>
                        WHEREAS, the Consultant is in the business of providing consultancy services to assist businesses in obtaining grants;
                    </Text>
                    <Text style={styles.recitals}>
                        WHEREAS, the Client desires to engage the services of the Consultant to assist in identifying and applying for business grants on behalf of the Client;
                    </Text>
                    <Text style={styles.recitals}>
                        NOW, THEREFORE, in consideration of the mutual promises, covenants, and conditions contained herein, the parties hereto agree as follows:
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>1. Services Provided</Text>
                    <Text style={styles.paragraph}>The Consultant agrees to provide the following services ("Services"):</Text>
                    <Text style={styles.paragraph}>1.1. Identifying suitable grant opportunities for the Client.</Text>
                    <Text style={styles.paragraph}>1.2. Assisting in the preparation and submission of grant applications.</Text>
                    <Text style={styles.paragraph}>1.3. Providing advisory support throughout the grant application process.</Text>
                    <Text style={styles.paragraph}>1.4. Other related services as agreed upon by both parties in writing.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>2. Fees and Payment</Text>
                    <Text style={styles.paragraph}>2.1. The Client agrees to pay the Consultant a fee equal to fifteen percent (15%) of the total grant amount awarded to the Client ("Consultancy Fee").</Text>
                    <Text style={styles.paragraph}>2.2. The Consultancy Fee is due and payable within seven (7) days of the Client receiving the grant funds.</Text>
                    <Text style={styles.paragraph}>2.3. In the event that the Client does not receive a grant, no Consultancy Fee shall be payable.</Text>
                    <Text style={styles.paragraph}>2.4. The Client agrees to reimburse the Consultant for any pre-approved out-of-pocket expenses incurred in the course of providing the Services, provided that such expenses are reasonable and necessary.</Text>
                    <Text style={styles.paragraph}>2.5. Services are subject to VAT charged at the current rate given by UK government at the time of payments billable.</Text>
                </View>
            </Page>
            <Page style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.header}>3. Term and Termination</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.paragraph}>3.1. This Agreement shall commence on the Effective Date and continue until the completion of the Services, unless earlier terminated in accordance with this Agreement.</Text>
                    <Text style={styles.paragraph}>3.2. Either party may terminate this Agreement for any reason upon thirty (30) days written notice to the other party.</Text>
                    <Text style={styles.paragraph}>3.3. In the event of termination, the Client shall pay the Consultant for all Services rendered and expenses incurred up to the date of termination.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>4. Confidentiality</Text>
                    <Text style={styles.paragraph}>4.1. The Consultant agrees to keep confidential all information disclosed by the Client in connection with the Services and shall not use or disclose such information except as necessary to perform the Services or as required by law.</Text>
                    <Text style={styles.paragraph}>4.2. The obligation of confidentiality shall survive the termination of this Agreement.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>5. Indemnification</Text>
                    <Text style={styles.paragraph}>The Client agrees to indemnify and hold harmless the Consultant from any and all claims, liabilities, damages, and expenses (including reasonable attorney's fees) arising out of or in connection with the Services provided under this Agreement.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>6. Governing Law</Text>
                    <Text style={styles.paragraph}>This Agreement shall be governed by and construed in accordance with the law of England and Wales whose courts shall have exclusive jurisdiction, without regard to its conflict of law principles.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>7. Entire Agreement</Text>
                    <Text style={styles.paragraph}>This Agreement constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, understandings, negotiations, and discussions, whether oral or written, of the parties. No amendment or modification of this Agreement shall be binding unless executed in writing by both parties.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>8. Severability</Text>
                    <Text style={styles.paragraph}>If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall continue in full force and effect.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>9. Waiver</Text>
                    <Text style={styles.paragraph}>No waiver of any term or condition of this Agreement shall be construed as a waiver of any other term or condition, nor shall any waiver of any breach of this Agreement be construed as a waiver of any subsequent breach.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>10. Notices</Text>
                    <Text style={styles.paragraph}>All notices, requests, demands, and other communications required or permitted under this Agreement shall be in writing and shall be deemed to have been duly given when delivered by hand, sent by certified mail, return receipt requested, or by recognized overnight courier service, to the addresses provided above or to such other address as either party may designate by notice in accordance with this provision.</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.header}>By signing I confirm that I have read and understood the contents of the agreement:</Text>
                    <Text style={styles.paragraph}>{clientName}</Text>
                </View>
                <View style={styles.section}>
                    {/* <Image style={styles.signeture} src='https://api.readysteadyportal.com/uploads/signature/1720646151587.jpg' /> */}
                    <Image style={styles.signeture} src={`${process.env.REACT_APP_SERVER_URL}/uploads/signature/${signature}`} />
                    <Text>-------------------------------</Text>
                    <Text>Sign Above This Line</Text>
                </View>
            </Page>
        </Document>
    );
}

export default Agreement1