import React, { useContext, useEffect, useState } from "react";
import EditLoan from "../../components/EditLoan/EditLoan";
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const CallBackList = () => {
    const { adminData, user } = useContext(AuthContext);
    const [loanList, setLoanList] = useState([]);
    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);
    const [loading, setLoading] = useState(true);
    const [businessName, setBusinessName] = useState();
    const [fundingManager, setFundingManager] = useState();

    useEffect(() => {
        loadData();
    }, [user, adminData]);

    const loadData = async (searchQuery = {}) => {
        setLoading(true);
        try {
            const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${localStorage.getItem("token")}` } };
            const body = { email: user?.email, searchQuery };
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v3/deals/call-back-list`, body, config);

            setLoanList(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response) {
                console.error("Error fetching deals:", error.response.data.message);
            } else {
                console.error("Error fetching deals:", error.message);
            }
        }
    };

    function convertTextToDate(text) {
        const [datePart, timePart, am] = text.split(" ");
        const [day, month, year] = datePart.split("-");

        const [hours, minutes] = timePart.split(":");
        const isAM = am === "AM";

        let hourNum = parseInt(hours, 10);
        if (hourNum === 12) {
            hourNum = isAM ? 0 : 12;
        } else {
            hourNum += isAM ? 0 : 12;
        }

        const date = new Date(year, month - 1, day, hourNum, minutes);

        return date;
    }

    const sortByCallBackDateAndTime = (array) => {
        return array.sort((a, b) => {
            const dateTimeA = convertTextToDate(a.callBack.date + " " + a.callBack.time);
            const dateTimeB = convertTextToDate(b.callBack.date + " " + b.callBack.time);

            if (dateTimeA < dateTimeB) {
                return -1;
            }
            if (dateTimeA > dateTimeB) {
                return 1;
            }
            return 0;
        });
    };

    sortByCallBackDateAndTime(loanList);

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
        setEditLoan({});
    };

    const rows = ["COMPANY NAME", "F. Manager", "Call Back", "ACTION"];

    const deleteCallBackEntry = async (id) => {
        setLoading(true);
        const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/update-callback/${id}`,
            { email: user?.email },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        console.log(response);

        setLoanList(loanList.filter((item) => item._id !== id));
        setLoading(false);
    };

    const handleSearch = () => {
        const queryObj = {};

        if (businessName) queryObj["business-name"] = businessName;
        if (fundingManager) queryObj["funding-manager"] = fundingManager;

        loadData(queryObj);
    };

    if (loading) {
        return (
            <div className="text-4xl mt-[35vh] flex  justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center">
                <div>
                    <input type="text" className="input h-10 input-bordered text-sm mr-2" placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    {adminData?.role === "super-admin" && (
                        <select className="input h-10 input-bordered text-sm mr-2" onChange={(e) => setFundingManager(e.target.value)} value={fundingManager}>
                            <option>Select</option>
                            <option value="Wayne">Wayne</option>
                            <option value="Peter">Peter</option>
                            <option value="Smit">Smit</option>
                            <option value="Jon">Jon</option>
                            <option value="Millie">Millie</option>
                            <option value="Mark">Mark</option>
                            <option value="Becher">Becher</option>
                            <option value="Don">Don</option>
                            <option value="Carl">Carl</option>
                            <option value="Janne">Janne</option>
                            <option value="Charity">Charity</option>
                            <option value="Steven">Steven</option>
                            <option value="Max">Max</option>
                            <option value="Hena">Hena</option>
                            <option value="ON_HOLD">ON_HOLD</option>
                        </select>
                    )}
                    <button onClick={handleSearch} className="button">
                        Search
                    </button>
                </div>
                <div>
                    <button onClick={() => (loadData(), setBusinessName(null), setFundingManager(null))} className="button mr-3">
                        Clear Filter
                    </button>
                </div>
            </div>
            <div className="mt-5 mb-20 rounded-lg bg-base-200">
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {rows.map((item, i) => (
                                    <th key={i} scope="col">
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => (
                                    <tr key={i}>
                                        <td className="font-[500]">{item["business-name"]}</td>
                                        <td>{item["funding-manager"] || "Pending"}</td>
                                        <td>
                                            <p>{new Date(item?.callBack?.date).toLocaleDateString("en-UK", { day: "numeric", month: "numeric", year: "numeric" }) + " ," + item?.callBack?.time}</p>
                                        </td>
                                        <td>
                                            <button onClick={() => deleteCallBackEntry(item?._id)} className="button mr-3">
                                                Complete
                                            </button>
                                            <Link className="button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl" to={`/admin/new-kanban/edit-deal/${item?._id}`}>
                                                <FaRegEye />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* popup */}
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoan item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
        </div>
    );
};

export default CallBackList;
