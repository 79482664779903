import React, { useEffect, useState } from 'react';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { IoMdSearch } from "react-icons/io";
import axios from 'axios';

const Trash = () => {
    const [loanList, setLoanList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortByDateState, setSortByDateState] = useState(false);
    const [sortByFundedAmount, setSortByFundedAmount] = useState(false);
    const [sortByRequiredAmount, setSortByRequiredAmount] = useState(false);
    const [searchRequestValue, setSearchRequestValue] = useState({});
    const navigate = useNavigate();


    const handleRestore = async (id) => {
        try {
            // Send a POST request to the Express API with the _id
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v2/move-object/${id}`);
            if (response.statusText === "OK") {
                navigate(`/admin/dashboard/edit-loan/${response.data.newId}`);
            }
        } catch (err) {
            // Handle error if object not found or other issues
            console.log(err.response?.data?.error || 'An error occurred while moving the object.');
        }
    };

    const sortDataByDate = () => {
        setSortByDateState(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateA - dateB;
        })
        setLoanList(sortedData)
    };

    const sortDataByDateRevarse = () => {
        setSortByDateState(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB - dateA;
        })
        setLoanList(sortedData)
    };

    const sortDataByFundedAmount = () => {
        setSortByFundedAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setLoanList(sortedData)
    };
    const sortDataByFundedAmountReverse = () => {
        setSortByFundedAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["funded-amount"] || 0;
            const dateB = b["funded-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setLoanList(sortedData)
    };

    const sortDataByRequirements = () => {
        setSortByRequiredAmount(true)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateA) - parseInt(dateB);
        })
        setLoanList(sortedData)
    };
    const sortDataByRequirementsReverse = () => {
        setSortByRequiredAmount(false)
        const sortedData = [...loanList].sort((a, b) => {
            const dateA = a["required-amount"] || 0;
            const dateB = b["required-amount"] || 0;
            return parseInt(dateB) - parseInt(dateA);
        })
        setLoanList(sortedData)
    };

    const updateSearchRequestValues = (e) => {
        setSearchRequestValue({
            ...searchRequestValue,
            [e.target.name]: e.target.value
        })
    }

    const updateNameSearchRequestValues = (e) => {
        e.preventDefault();
        setSearchRequestValue({
            ...searchRequestValue,
            "business-name": e.target["business-name"].value
        })
    }

    useEffect(() => {
        setLoanList([]);
        setLoading(true)
        const url = `${process.env.REACT_APP_SERVER_URL}/trash-deals/GETALL`;
        const requestBody = JSON.stringify(searchRequestValue);

        fetch(url, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                const sortedData = [...data].sort((a, b) => {
                    const dateA = new Date(a.createdDate);
                    const dateB = new Date(b.createdDate);
                    return dateB - dateA;
                });
                setLoanList(sortedData);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [searchRequestValue])

    const rows = ["COMPANY NAME", "CREATED", "REQUIREMENT", "FUNDED AMOUNT", "TYPE", "STATUS", "FUNDING MANAGER", "ACTION"];

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex  justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }

    return (
        <div className='mt-10 mr-10'>
            <div className='mt-5 mb-20 rounded-lg bg-base-200'>
                <div className="overflow-x-auto p-2">
                    <table className="table p-5">
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {
                                            (item === "FUNDED AMOUNT" && item !== "CREATED") ? (sortByFundedAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmount} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByFundedAmountReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>) : ((item !== "CREATED" && item !== "REQUIREMENT") && item)
                                        }

                                        {
                                            item === "CREATED" && (sortByDateState === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDate} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByDateRevarse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }

                                        {
                                            item === "REQUIREMENT" && (sortByRequiredAmount === false ? <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirements} className='cursor-pointer'><BiSolidDownArrow /></span></p> : <p className='flex justify-start items-center gap-2'>{item} <span onClick={sortDataByRequirementsReverse} className='cursor-pointer'><BiSolidUpArrow /></span></p>)
                                        }
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {
                                            item === "COMPANY NAME" && <form onSubmit={(e) => updateNameSearchRequestValues(e)} className='flex'><input name='business-name' type="text" className="text-xs w-full border-2 p-2 font-normal" /><button type='submit' className='text-white text-xl ml-2 p-2 bg-primary rounded'><IoMdSearch /></button></form>
                                        }

                                        {
                                            item === "STATUS" && <select onChange={(e) => updateSearchRequestValues(e)} name='status' className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>Status</option>
                                                <option value="Initial Review">Initial Review</option>
                                                <option value="Additional Documents">Additional Documents</option>
                                                <option value="Underwriting">Underwriting</option>
                                                <option value="Offer">Offer</option>
                                                <option value="Contract Sent">Contract Sent</option>
                                                <option value="Funded">Funded</option>
                                                <option value="Last Chance">Last Chance</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Cancelled for ever">Cancelled for ever</option>
                                                <option value="Start Review">Start Review</option>
                                                <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                                                <option value="Pitch Creation">Pitch Creation</option>
                                                <option value="Online Posted">Online Posted</option>
                                                <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                                                <option value="Awaiting Payment">Awaiting Payment</option>
                                            </select>
                                        }
                                        {
                                            item === "FUNDING MANAGER" && <select name='funding-manager' onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>Funding Manager</option>
                                                <option value="virgil" defaultValue>Virgil</option>
                                                <option value="Smit">Smit</option>
                                                <option value="Tan">Tan</option>
                                                <option value="Support">Support</option>
                                                <option value="Team">Team</option>
                                            </select>
                                        }
                                        {
                                            item === "TYPE" && <select name='type' onChange={(e) => updateSearchRequestValues(e)} className="text-xs border-2 p-2 font-normal">
                                                <option value="all" defaultValue>Type</option>
                                                <option value="Business Loan" defaultValue>Business Loan</option>
                                                <option value="Grants">Grants</option>
                                                <option value="MCA">MCA</option>
                                                <option value="Renewal Loan">Renewal Loan</option>
                                                <option value="Renewal MCA">Renewal MCA</option>
                                            </select>
                                        }
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => <tr key={i}>
                                    <td className='font-[500]'>
                                        {item["business-name"]}
                                    </td>
                                    <td >
                                        {new Date(item?.createdDate).toLocaleDateString('en-UK', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </td>
                                    <td >
                                        {item["country"]} {parseInt(item["required-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })}
                                    </td>
                                    <td>
                                        {item["country"]}  {parseInt(item["funded-amount"]).toString() !== "NaN"
                                            ? parseInt(item["funded-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })
                                            : '0'}
                                    </td>
                                    <td >
                                        {item["type"]}
                                    </td>
                                    <td>
                                        {item["status"] || 'Initial Review'}
                                    </td>
                                    <td>
                                        {item["funding-manager"] || 'Pending'}
                                    </td>
                                    <td>
                                        <button type="button" className='button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white' onClick={e => handleRestore(item?._id)}>Restore</button>
                                        {/* <Link className='button !bg-gray-50 !shadow-none !text-black hover:!bg-primary hover:!text-white !text-xl' to={`/admin/dashboard/edit-loan/${item?._id}`}><FaRegEye /></Link> */}
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Trash