import React, { useState, useEffect } from "react";
import moment from "moment";
import emailjs from "@emailjs/browser";
import { useParams } from "react-router-dom";
import { CalenderDateDayContainerDisable } from "../../styledComponent/index";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { PacmanLoader } from "react-spinners";
import { AiOutlineDelete } from "react-icons/ai";

const weekArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const gridArray = Array.from({ length: 42 }, (_, i) => i);

const yearOptions = ["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034"];

const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
];

const Calender = () => {
    const { adminData, user } = useContext(AuthContext);
    const { year: defaultYearParam, month: defaultMonthParam } = useParams();
    const { register, handleSubmit, reset } = useForm();

    const employeeList = {
        "super-admin": ["Wayne", "Peter", "Sazzad", "Millie", "Jon", "Smit", "Mark", "Becher", "Carl", "Don", "Janne", "Charity", "Steven", "Hena"],
        "jon@readysteadyfinance.co.uk": ["Jon", "Don", "Becher", "Janne"],
        "don@sedgefunding.co.uk": ["Don", "Becher", "Janne"],
        "steven@sedgefunding.co.uk": ["Steven", "Smit", "Carl", "Charity"],
        "smit@readysteadyfinance.co.uk": ["Carl", "Charity"],
    };
    const userAccessList = adminData.role === "super-admin" ? employeeList["super-admin"] : employeeList[user?.email] || [];

    const [selectedYear, setSelectedYear] = useState(parseInt(defaultYearParam) || moment().year());
    const [selectedMonth, setSelectedMonth] = useState(parseInt(defaultMonthParam) - 1 || moment().month());
    const [modalState, setModalState] = useState(false);
    const [holidayRequest, setHolidayRequest] = useState([]);
    const [holidayDateError, setHolidayDateError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [holidayRequestData, setHolidayRequestData] = useState({});

    const startOfDay = moment().year(selectedYear).month(selectedMonth).startOf("month").format("ddd");
    const monthSize = parseInt(moment().year(selectedYear).month(selectedMonth).endOf("month").format("DD"));

    const startIndex = weekArray.indexOf(startOfDay);
    const endIndex = startIndex + monthSize;

    const openCalenderRequestApprovalPopup = (props) => {
        setHolidayRequestData(props);
        window.calenderRequestApprovalPopup.showModal();
    };

    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
    };

    const fetchHolidayRequests = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/holiday-request/GETALL`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ email: user.email }),
        })
            .then((response) => response.json())
            .then((data) => {
                setHolidayRequest(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const deleteHolidayRequest = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/delete-holiday-request/${holidayRequestData._id}`, {
            method: "DELETE",
        })
            .then((response) => response.json())
            .then((res) => {
                fetchHolidayRequests();
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const submitHolidyRequestApproval = (e) => {
        e.preventDefault();
        const dialog = document.getElementById("calenderRequestApprovalPopup");
        const loanData = {
            status: e?.target?.request?.value,
            note: e?.target?.note?.value,
            updated: true,
            approvedBy: user.displayName,
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/update-holiday-request/${holidayRequestData._id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(loanData),
        })
            .then((response) => response.json())
            .then((result) => {
                e.target.reset();
                dialog.close();
                fetchHolidayRequests();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        fetchHolidayRequests();
    }, []);

    function formatDate(from, to) {
        const fromDate = new Date(from);
        const toDate = new Date(to);

        const fromDateString = fromDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
        const toDateString = toDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

        return `${fromDateString} - ${toDateString}`;
    }

    // function CustomAppointment({ data }) {
    //     return (
    //         <div onClick={() => openCalenderRequestApprovalPopup(data)} className={`${(data.name === "Peter" || data.name === "Wayne") && adminData.role === "admin" && ("hidden")} ${(data.email === "peter@sedgefunding.co.uk" || data.email === "wayne@sedgefunding.co.uk") && adminData.role !== "super-admin" && ("hidden")} ${data.status==="Requested" && "bg-yellow-500"} ${data.status==="Approved" && "bg-green-500"} ${data.status==="Denied" && "bg-red-500"} ${data.status==="Unpaid" && "bg-blue-500"} border-l-4 border-primary mx-2 text-left pl-2 text-sm text-white py-3 cursor-pointer ${data.sickLeave ? (adminData.role === "super-admin" ? "block" : "hidden") : "block"}`}>
    //             {data?.other && data?.other.length !== 0 ? data.other : `${data.name} - ${data.status}`}
    //             {data?.sickLeave && <p>Sick Leave</p>}
    //         </div>
    //     );
    // }

    function CustomAppointment({ data }) {
        console.log(employeeList[user.email]?.includes(data.name));

        const classNames = [
            "border-l-4 mx-2 text-left pl-2 text-sm text-white py-3 cursor-pointer",
            data.status === "Requested" && "bg-yellow-500 border-yellow-500",
            data.status === "Approved" && "bg-green-500 border-green-500",
            data.status === "Denied" && "bg-red-500 border-red-500",
            data.status === "Unpaid" && "bg-blue-500 border-blue-500",
            data?.other && data?.other.length !== 0 && "bg-violet-500 border-violet-500",
            // data.sickLeave ? (adminData.role === "super-admin" || user.displayName === data.name ? "block" : "hidden") : "block",
            // data.sickLeave ? (adminData.role === "super-admin" || employeeList[user.email]?.includes(data.name) ? "block" : "hidden") : "block",
            data.sickLeave ? (adminData.role === "super-admin" || user.displayName === data.name || employeeList[user.email]?.includes(data.name) ? "block" : "hidden") : "block",
            (data.name === "Peter" || data.name === "Wayne") && adminData.role === "admin" && "hidden",
            (data.name === "Peter" || data.name === "Wayne") && adminData.role !== "super-admin" && "hidden",
        ]
            .filter(Boolean)
            .join(" ");

        console.log(data);
        console.log(classNames);

        return (
            <div onClick={() => openCalenderRequestApprovalPopup(data)} className={classNames}>
                {data?.other && data?.other.length !== 0 ? data.other : `${data.name} - ${data?.sickLeave ? "Sick Leave" : data.status}`}
            </div>
        );
    }

    // Contents of CalenderHeader.js
    function CustomCalenderHeader(props) {
        console.log(props);

        const onYearSelect = (date) => {
            console.log(date);
            props.onYearSelect(date);
        };

        const onMonthSelect = (date) => {
            setSelectedMonth(parseInt(date) - 1);
        };

        const openModal = () => {
            window.calenderAddRequestPopup.showModal();
        };

        return (
            <div className="flex w-full justify-between">
                <div className="flex gap-2">
                    <select className="select select-bordered" value={props.defaultYear} onChange={(e) => onYearSelect(e.target.value)}>
                        <option key={selectedYear} value={selectedYear}>
                            {selectedYear}
                        </option>
                        {yearOptions.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                    <select className="select select-bordered" value={props.defaultMonth} onChange={(e) => onMonthSelect(e.target.value)}>
                        {monthOptions.map((month) => (
                            <option key={month.value} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="button" onClick={openModal}>
                    Request for holidays
                </div>
            </div>
        );
    }

    // Contents of DateComponent.js
    function CustomDateComponent(props) {
        const [userData, setUserData] = useState([]);
        const date = props.year + "-" + props.month + "-" + props.date;

        useEffect(() => {
            if (props.userData) {
                const filteredUserData = props.userData.filter((data) => {
                    try {
                        // console.log(data.from);
                        const dataDate = new Date(data.from);
                        const inputDate = new Date(date);
                        // Compare year, month, and day separately
                        return dataDate.getFullYear() === inputDate.getFullYear() && dataDate.getMonth() === inputDate.getMonth() && dataDate.getDate() === inputDate.getDate();
                    } catch (error) {
                        return false;
                    }
                });
                setUserData(filteredUserData);
            }
        }, [props.userData, date]);

        const appointment = userData.map((data, index) => <CustomAppointment key={index} data={data} />);
        return (
            <div>
                <div className="mr-2 mt-2">{props.date}</div>
                <div className="flex flex-col gap-2">{appointment}</div>
            </div>
        );
    }

    const onSubmit = (data) => {
        const fromDate = new Date(data.From);

        // Function to check if a date is a working day (Mon-Fri)
        const isWorkingDay = (date) => {
            const day = date.getDay();
            return day !== 0 && day !== 6; // 0 is Sunday, 6 is Saturday
        };

        // Function to get the first 3 working days of the month
        const getFirstWorkingDays = (year, month, count) => {
            let workingDays = [];
            for (let day = 1; workingDays.length < count; day++) {
                let date = new Date(year, month, day);
                if (isWorkingDay(date)) {
                    workingDays.push(date);
                }
            }
            return workingDays;
        };

        // Function to get the last 4 working days of the month
        const getLastWorkingDays = (year, month, count) => {
            let workingDays = [];
            let lastDay = new Date(year, month + 1, 0).getDate(); // Get last day of the month
            for (let day = lastDay; workingDays.length < count; day--) {
                let date = new Date(year, month, day);
                if (isWorkingDay(date)) {
                    workingDays.push(date);
                }
            }
            return workingDays;
        };

        const year = fromDate.getFullYear();
        const month = fromDate.getMonth();

        const first3WorkingDays = getFirstWorkingDays(year, month, 3);
        const last4WorkingDays = getLastWorkingDays(year, month, 4);

        const isFirst3WorkingDays = first3WorkingDays.some((date) => date.getDate() === fromDate.getDate());
        const isLast4WorkingDays = last4WorkingDays.some((date) => date.getDate() === fromDate.getDate());

        if (adminData?.role !== "super-admin" && !data?.sickLeave === true && (isFirst3WorkingDays || isLast4WorkingDays)) {
            setHolidayDateError("You cannot book a holiday for the first 3 days or the last 4 days of the month.");
            return;
        }

        const requestData = {
            name: data.Name,
            purpose: data?.Purpose || "Holiday Request",
            from: data?.From,
            other: data?.Other.length !== 0 ? data?.Other : undefined,
            status: data?.sickLeave === true ? "Approved" : "Requested",
            sickLeave: data?.sickLeave,
            halfDay: data?.halfDay,
        };

        if (data.From) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/request-for-holidays`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            })
                .then((response) => response.json())
                .then((result) => {
                    fetchHolidayRequests();
                    closeModal("calenderAddRequestPopup");
                    // Ensure data is defined and has expected properties
                    if (data && data.Name && data.From) {
                        // Parse and format the date if data.From is a valid date
                        const formattedDate = data.From instanceof Date ? data.From.toLocaleDateString("en-GB") : new Date(data.From).toLocaleDateString("en-GB");

                        // Send the email using EmailJS

                        emailjs
                            .send(
                                "portal_calender",
                                "portal_calender_template",
                                {
                                    name: data.Name,
                                    purpose: data.Purpose || "Holiday Request",
                                    date: formattedDate,
                                    other: data.Other?.length !== 0 ? data.Other : undefined,
                                    status: data.sickLeave ? "Approved" : "Requested",
                                    sickLeave: data.sickLeave,
                                    halfDay: data.halfDay,
                                },
                                "pH0fYO6wGMmzTkVH8"
                            )
                            .then(
                                function (response) {
                                    console.log("SUCCESS!", response.status, response.text);
                                },
                                function (error) {
                                    console.log("FAILED...", error);
                                }
                            );
                    } else {
                        console.error("Invalid data: 'Name' and 'From' fields are required.");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
        reset();
    };

    useEffect(() => {
        setSelectedYear(parseInt(defaultYearParam) || moment().year());
        setSelectedMonth(parseInt(defaultMonthParam) - 1 || moment().month());
    }, [defaultYearParam, defaultMonthParam]);

    const onYearSelect = (year) => {
        setSelectedYear(parseInt(year));
    };

    const onMonthSelect = (month) => {
        const { value } = month;
        setSelectedMonth(parseInt(value - 1));
    };

    const openModal = () => {
        setModalState(!modalState);
    };

    function getObjectIdTimestamp(objectId) {
        if (!objectId || typeof objectId !== "string") {
            console.log("Invalid ObjectId");
        }

        const timestampHex = objectId.substring(0, 8);
        const timestamp = parseInt(timestampHex, 16) * 1000;
        return new Date(timestamp).toLocaleDateString("en-GB");
    }

    const onModalSubmit = (data) => {
        const holidayRequestData = {
            name: user.displayName,
            purpose: data?.Purpose,
            from: data?.From,
            to: data?.To,
            email: user?.email,
            status: "Requested",
        };
        setHolidayRequest([...holidayRequest, holidayRequestData]);
        setModalState(false);
    };

    console.log(user.displayName);

    if (loading) {
        return (
            <div className="text-4xl mt-[35vh] flex  justify-center items-center">
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col mt-10">
                <CustomCalenderHeader onYearSelect={onYearSelect} onMonthSelect={onMonthSelect} defaultYear={selectedYear.toString()} defaultMonth={(selectedMonth + 1).toString()} openModal={openModal} />
                <div className="mt-2">
                    <div className="grid grid-cols-7 bg-base-200">
                        {weekArray.map((data, i) => (
                            <div className="p-3 text-center" key={i}>
                                {data}
                            </div>
                        ))}
                    </div>
                    <div className="grid grid-cols-7 gap-[1px]">
                        {gridArray.map((data, i) =>
                            i >= startIndex && i < endIndex ? (
                                <div className=" bg-base-200 text-right h-[150px] overflow-y-auto" key={i}>
                                    <CustomDateComponent userData={holidayRequest} date={i - startIndex + 1} month={selectedMonth + 1} year={selectedYear} />
                                </div>
                            ) : (
                                <CalenderDateDayContainerDisable key={i}></CalenderDateDayContainerDisable>
                            )
                        )}
                    </div>
                </div>
            </div>

            <dialog id="calenderAddRequestPopup" className="modal w-[600px] max-w-[600px] mx-auto">
                <div className="p-10 bg-base-100 w-[600px]">
                    <div className="font-bold text-center text-lg">Request for a Holiday</div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-full">
                            {holidayDateError && (
                                <div role="alert" className="alert alert-error">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>{holidayDateError}</span>
                                </div>
                            )}
                            <div className={`w-full flex justify-between items-center mt-5 ${userAccessList.length === 0 ? "hidden" : ""}`}>
                                <div className="w-1/3">Name:</div>
                                <select name="type" id="Name" className="input h-10 input-bordered text-sm w-2/3" {...register("Name")} defaultValue={adminData?.name}>
                                    <option value="">Select a Name</option>
                                    {userAccessList.map((name) => (
                                        <option key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-full flex justify-between items-center mt-5">
                                <div className="w-1/3">Purpose:</div>
                                <input className="input h-10 input-bordered text-sm w-2/3" {...register("Purpose")} />
                            </div>
                            <div className="w-full flex justify-between items-center mt-5">
                                <div>Date:</div>
                                <input type="date" className="input h-10 input-bordered w-2/3 text-sm" {...register("From")} />
                            </div>
                            <div className={`w-full flex justify-between items-center mt-5 ${adminData.role !== "super-admin" && "hidden"}`}>
                                <div className="w-1/3">Other Holiday:</div>
                                <input className="input h-10 input-bordered text-sm w-2/3" {...register("Other")} />
                            </div>
                            <div className="w-full items-center mt-5">
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Sick Leave:</span>
                                        <input type="checkbox" className="checkbox" {...register("sickLeave")} />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Half Day:</span>
                                        <input type="checkbox" className="checkbox" {...register("halfDay")} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-between mt-10">
                            <button type="submit" className="button">
                                Submit
                            </button>
                            <button onClick={() => closeModal("calenderAddRequestPopup")} type="button" className="button !bg-gray-400">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </dialog>
            <dialog id="calenderRequestApprovalPopup" className="modal w-[600px] max-w-[600px] mx-auto">
                <form method="dialog" onSubmit={(e) => submitHolidyRequestApproval(e)} className="modal-box p-10 w-[550px] max-w-[550px] rounded-lg">
                    <div className="flex justify-between items-center mb-5">
                        <p className="text-2xl font-bold">Holiday Request</p>
                        {adminData.role === "super-admin" && <AiOutlineDelete fontSize={"20px"} className="hover:cursor-pointer" onClick={deleteHolidayRequest} />}
                    </div>
                    <div className="grid grid-cols-1 gap-3">
                        <p className="custom-grid-calender-view-popup">
                            <span>Request Date: </span> <span>:</span> <span>{holidayRequestData._id && getObjectIdTimestamp(holidayRequestData._id)}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Name</span> <span>:</span> <span>{holidayRequestData.name}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Purpose</span> <span>:</span> <span>{holidayRequestData.purpose}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Sick Leave</span> <span>:</span> <span>{holidayRequestData?.sickLeave ? <span className="bg-green-500 text-white p-3 py-1 rounded">Yes</span> : <span className="bg-red-700 text-white p-3 py-1 rounded">No</span>}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Half Day</span> <span>:</span> <span>{holidayRequestData?.halfDay ? <span className="bg-green-500 text-white p-3 py-1 rounded">Yes</span> : <span className="bg-red-700 text-white p-3 py-1 rounded">No</span>}</span>
                        </p>
                        {/* <p className='custom-grid-calender-view-popup'><span>Date:</span> <span>:</span>  <span>{holidayRequestData.from}</span></p> */}
                        {/* <p className='custom-grid-calender-view-popup'><span>To</span> <span>:</span>  <span>{holidayRequestData.to}</span></p> */}
                        <p className="custom-grid-calender-view-popup">
                            <span>Status</span> <span>:</span> <span>{holidayRequestData.status}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Approved By</span> <span>:</span> <span>{holidayRequestData.approvedBy}</span>
                        </p>
                        <p className="custom-grid-calender-view-popup">
                            <span>Note</span> <span>:</span> <span>{holidayRequestData.note}</span>
                        </p>

                        {adminData.role === "super-admin" && (
                            <select name="request" className="input input-bordered" required>
                                <option value={holidayRequestData.status || "Pending"} defaultValue>
                                    Pending
                                </option>
                                <option value="Approved">Approved Leave</option>
                                <option value="Unpaid">Unpaid Leave</option>
                                <option value="Denied">Denied</option>
                            </select>
                        )}
                    </div>
                    {adminData.role === "super-admin" && <input name="note" type="text" placeholder="Note" className="input mt-3 w-full input-bordered" />}

                    <div className={`flex gap-3 mt-8 ${adminData.role !== "super-admin" ? "justify-end" : "justify-between"}`}>
                        {adminData.role === "super-admin" && (
                            <button type="submit" className="button !rounded">
                                submit
                            </button>
                        )}
                        <button type="button" onClick={() => closeModal("calenderRequestApprovalPopup")} className="button !text-[#000] !bg-[#f2f2f2] !rounded">
                            Close
                        </button>
                    </div>
                </form>
            </dialog>
        </>
    );
};

export default Calender;
