import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { PacmanLoader } from "react-spinners";

const RequestNewDeal = () => {
	const { user } = useContext(AuthContext);
	const { id } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [loanData, setLoanData] = useState({});
	const [teamNote, setTeamNote] = useState("");
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedTime, setSelectedTime] = useState("");
	// const loanData = {
	//     ownerName: "Dave Smith",
	//     businessName: "Smiths & Sons",
	//     phoneNumber: "0123 456789",
	// };
	const [formData, setFormData] = useState({
		"business-notes": loanData["business-notes"] || "",
		"loan-purpose": loanData["loan-purpose"] || "",
		"business-name": loanData["business-name"] || "",
		"business-address": loanData["business-address"] || "",
		"business-owner-name": loanData["business-owner-name"] || "",
		"business-phone": loanData["business-phone"] || "",
		"business-email": loanData["business-email"] || "",
		"avarage-monthly-turnover": loanData["avarage-monthly-turnover"] || "",
		"marchent-turnover": loanData["marchent-turnover"] || "",
		"birth-date": loanData["birth-date"] || "",
		"overdraft-limit": loanData["overdraft-limit"] || "",
		"required-amount": loanData["required-amount"] || "",
		"home-owner": loanData["home-owner"] || "",
		"home-address": loanData["home-address"] || "",
		creditCheckPermission: loanData.creditCheckPermission || "", // Radio button value
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Validate all fields are filled
		for (const key in formData) {
			if (!formData[key]) {
				alert(`Please fill out the ${key.replace("-", " ")} field.`);
				return;
			}
		}

		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/submit-loan-form`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				body: JSON.stringify({
					email: user.email,
					dealId: loanData?._id,
					updatedData: formData,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to submit form");
			}

			const result = await response.json();
			navigate("/admin/new-deal-action");
		} catch (error) {
			console.error("Error submitting form:", error);
			alert("Failed to submit form. Please try again.");
		}
	};

	const handleNoteSubmit = () => {};
	const handleDateChange = (event) => {
		setSelectedDate(event.target.value);
	};
	const handleTimeChange = (event) => {
		setSelectedTime(event.target.value);
	};

	const handleCancelReason = async (reason, dealId) => {
		try {
			if (["No Answer", "Voicemail", "Wrong Number", "Not Interested"].includes(reason)) {
				await moveDealToBottom(dealId);
			} else if (["Take number off system", "Call Head Office / part of group", "Wrong industry (law, finance, gov...)"].includes(reason)) {
				await markDealAsCanceledForever(dealId);
			}
			console.log("Deal updated successfully");
		} catch (error) {
			console.error("Error updating deal:", error);
		}
	};

	// Function to call the "move deal to bottom" API
	const moveDealToBottom = async (dealId) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/v3/move-deal-to-bottom`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ dealId }),
			});

			if (!response.ok) {
				throw new Error("Failed to move deal to bottom");
			}

			const data = await response.json();
			navigate("/admin/new-deal-action");
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	};

	// Function to call the "mark deal as canceled forever" API
	const markDealAsCanceledForever = async (dealId) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/mark-deal-as-canceled-forever`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ dealId }),
			});

			if (!response.ok) {
				throw new Error("Failed to mark deal as canceled forever");
			}

			const data = await response.json();
			navigate("/admin/new-deal-action");
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	};

	const interestedCustomerCallBack = () => {
		const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/move-interested-customer`;
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				email: user.email,
				dealId: loanData?._id,
				updatedData: { callBack: { date: selectedDate, time: selectedTime } },
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				setLoading(false);
				navigate("/admin/new-deal-action");
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const generateTimeSlots = () => {
		const timeSlots = [];
		const startTime = 8 * 60;
		const endTime = 20 * 60;
		const interval = 15;

		for (let i = startTime; i < endTime; i += interval) {
			const hours = Math.floor(i / 60);
			const minutes = i % 60;
			const ampm = hours >= 12 ? "PM" : "AM";
			const displayHours = hours % 12 === 0 ? 12 : hours % 12;
			const formattedTime = `${displayHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
			timeSlots.push(formattedTime);
		}

		return timeSlots;
	};

	useEffect(() => {
		const url = `${process.env.REACT_APP_SERVER_URL}/api/v3/request-deal`;
		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				email: user.email,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				setLoanData(data.value);
				setFormData({
					"business-notes": data.value["business-notes"] || "",
					"loan-purpose": data.value["loan-purpose"] || "",
					"business-name": data.value["business-name"] || "",
					"business-address": data.value["business-address"] || "",
					"business-owner-name": data.value["business-owner-name"] || "",
					"business-phone": data.value["business-phone"] || "",
					"business-email": data.value["business-email"] || "",
					"avarage-monthly-turnover": data.value["avarage-monthly-turnover"] || "",
					"marchent-turnover": data.value["marchent-turnover"] || "",
					"birth-date": data.value["birth-date"] || "",
					"overdraft-limit": data.value["overdraft-limit"] || "",
					"required-amount": data.value["required-amount"] || "",
					"home-owner": data.value["home-owner"] || "",
					"home-address": data.value["home-address"] || "",
					creditCheckPermission: data.value.creditCheckPermission || "", // Radio button value
				});
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	if (loading) {
		return (
			<div className="text-4xl mt-[35vh] flex justify-center items-center">
				<PacmanLoader color="#e63a27" />
			</div>
		);
	}

	return (
		<div className="w-full mx-auto p-6 bg-base-100 mt-10 rounded-lg">
			<h2 className="text-xl font-semibold mb-4">New Deal</h2>

			<div className="grid grid-cols-2 gap-20">
				{/* Input Fields */}
				<div>
					<div className="mb-4">
						<label className="block">Owner Name</label>
						<input type="text" value={loanData["business-owner-name"]} readOnly className="w-full mt-1 p-2 border border-base-300 rounded bg-base-200" />
					</div>
					<div className="mb-4">
						<label className="block">Business Name</label>
						<input type="text" value={loanData["business-name"]} readOnly className="w-full mt-1 p-2 border border-base-300 rounded bg-base-200" />
					</div>
					<div className="mb-4">
						<label className="block">Phone Number</label>
						<input type="text" value={loanData["business-phone"]} readOnly className="w-full mt-1 p-2 border border-base-300 rounded bg-base-200" />
					</div>
				</div>

				{/* Cancel Section */}
				<div>
					<h3 className="text-lg font-semibold mb-2">Cancel</h3>
					<div className="space-y-2">
						{["No Answer", "Voicemail", "Wrong Number", "Not Interested", "Take number off system", "Call Head Office / part of group", "Wrong industry (law, finance, gov...)"].map((reason, index) => (
							<div className="flex items-center">
								<label key={index} className="space-x-2">
									<input type="radio" name="cancelReason" onChange={() => handleCancelReason(reason, loanData?._id)} className="border border-base-300 bg-base-200" />
									<span>{reason}</span>
								</label>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* New Pitch Button */}
			<div>
				<div className="p-10 pl-0">
					<h3 className="text-lg font-semibold mb-2">Keep Deal</h3>
					<div className="text-xl mb-5">
						Hi May I speak to <span className="font-semibold">{loanData["business-owner-name"] || "-"}</span> (Don’t ask for the business owner) <br />
						<br />
						<span className="font-semibold text-red-500">NOT IN</span> - no problem when will they be back, its not hugely important just don’t want to keep bugging you that’s all. <br />
						<div className="flex items-center mt-5">
							<input type="date" id="date" name="date" value={selectedDate} onChange={handleDateChange} className="input border-gray-400 border mr-3" required />
							<select id="time" name="time" className="input border-gray-400 border mr-3" value={selectedTime} onChange={handleTimeChange} required>
								<option value="">Select a time</option>
								{generateTimeSlots().map((timeSlot, index) => (
									<option key={index} value={timeSlot}>
										{timeSlot}
									</option>
								))}
							</select>
							<button type="button" onClick={interestedCustomerCallBack} className="btn bg-primary text-white">
								Call Back
							</button>
						</div>
						<br />
						<span className="font-semibold text-green-500">IN</span> - This is <span className="font-semibold ">{user?.displayName}</span> calling from Ready Steady Finance. How are you doing, How’s the business so far?
						<br />
						<br />
						Awesome!!
						<br />
						<br />
						Just a quick one, Have you been applying for a business Loan/MCA?
						<br />
						<br />
						OK, no worries. Im here to help you. <br />
						<br />
						Yeah the reason why im calling is to let you know that our company is providing no obligation services whether a small to medium businesses in getting funding or any other type of funding that the business might need.
						<br />
						<br />
						So What we do is help you apply for Business loans, MCA or Invoice Finance. Just to add, once you have been approved for a MCA or a business loan, you are not obligated to accept nor there will be no lock in contracts right away so you can review the offer in detail before you accept.
						<br />
						<br />
						If you gave me one shot on this, believe me {loanData["business-owner-name"] || "-"} the only thing I can assure you 100% is that you’ve found a finance company that you can trust and consistently make your business grow. <br />
						<br />
						Just need to confirm some of the Basic information and it will be very Quick.
						<br />
						<br />
					</div>

					<div className="divider my-5"></div>

					<form className="p-10 pl-0" onSubmit={handleSubmit}>
						<div className="w-1/2">
							<label htmlFor="business-notes" className="text-xl mb-1 block">
								What type of business do you have?
							</label>
							<input onChange={handleChange} value={formData["business-notes"]} name="business-notes" type="text" placeholder="Notes" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="loan-purpose" className="text-xl mb-1 block">
								1) Is there any new equipment you need? <br />
								2) What market idea for the future?
							</label>
							<input onChange={handleChange} value={formData["loan-purpose"]} name="loan-purpose" type="text" placeholder="Loan Purpose" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<p className="text-lg mb-2">
							Let look what info I have. <br />
							Following information:
						</p>
						<div className="mb-4 w-1/2">
							<label htmlFor="business-name" className="text-xl mb-1 block">
								So, I have the business name as:
							</label>
							<input onChange={handleChange} value={formData["business-name"]} name="business-name" type="text" placeholder="Business Name" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="business-address" className="text-xl mb-1 block">
								And the address you are registered?
							</label>
							<input onChange={handleChange} value={formData["business-address"]} name="business-address" type="text" placeholder="Business Address" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="business-owner-name" className="text-xl mb-1 block">
								Contact Name:
							</label>
							<input onChange={handleChange} value={formData["business-owner-name"]} name="business-owner-name" type="text" placeholder="Business Contact Name" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="home-address" className="text-xl mb-1 block">
								Home address:
							</label>
							<input onChange={handleChange} value={formData["home-address"]} name="home-address" type="text" placeholder="Home Address" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="home-owner" className="text-xl mb-1 block">
								Home Owner / Rent:
							</label>
							<input onChange={handleChange} value={formData["home-owner"]} name="home-owner" type="text" placeholder="Home Owner / Rent" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="required-amount" className="text-xl mb-1 block">
								Required Amount:
							</label>
							<input onChange={handleChange} value={formData["required-amount"]} name="required-amount" type="text" placeholder="Required Amount" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="overdraft-limit" className="text-xl mb-1 block">
								Overdraft Limit:
							</label>
							<input onChange={handleChange} value={formData["overdraft-limit"]} name="overdraft-limit" type="text" placeholder="Overdraft Limit" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="birth-date" className="text-xl mb-1 block">
								Birth Date:
							</label>
							<input onChange={handleChange} value={formData["birth-date"]} name="birth-date" type="date" placeholder="Overdraft Limit" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						{/* <div className="my-8 w-1/2">
                            <label htmlFor="business-phone" className="text-xl mb-1 block">
                                Business Phone Number:
                            </label>
                            <input onChange={handleChange} value={formData["business-phone"]} name="business-phone" type="tel" placeholder="Phone Number" className="p-3 rounded-lg bg-base-300 w-full" required />
                        </div> */}
						<div className="my-8 w-1/2">
							<label htmlFor="business-email" className="text-xl mb-1 block">
								Email Address:
							</label>
							<input onChange={handleChange} value={formData["business-email"]} name="business-email" type="email" placeholder="Email" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="avarage-monthly-turnover" className="text-xl mb-1 block">
								How much is your Turnover every month?
							</label>
							<input onChange={handleChange} value={formData["avarage-monthly-turnover"]} name="avarage-monthly-turnover" type="text" placeholder="Average Monthly Turnover" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div className="my-8 w-1/2">
							<label htmlFor="marchent-turnover" className="text-xl mb-1 block">
								1) Is any of that turn over using websites like your own or ebay amazon <br />
								2) Any by a card machine or epos est ...
							</label>
							<input onChange={handleChange} value={formData["marchent-turnover"]} name="marchent-turnover" type="text" placeholder="Merchant Turnover" className="p-3 rounded-lg bg-base-300 w-full" required />
						</div>
						<div>
							<p className="text-xl">
								Based on the information supplied we would look to offer you - <br />
								<ul>
									<li>
										<b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Long term loan 2-5 years - £ ${parseInt(2 * formData["avarage-monthly-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>{" "}
									</li>
									<li>
										<b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Short term loan 6m - 2 Years -  £ ${parseInt(1.5 * formData["avarage-monthly-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
									</li>
									<li>
										<b>{parseInt(formData["marchent-turnover"]) !== 0 && `MCA -  £ ${parseInt((150 / 100) * formData["marchent-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
									</li>
									<li>
										<b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && formData["loan-purpose"] === "Invoice Finance" && `Invoice finance -  £ ${parseInt((40 / 100) * formData["avarage-monthly-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>
									</li>
									<li>
										<b>{parseInt(formData["avarage-monthly-turnover"]) !== 0 && `Stock Finance  -  £ ${parseInt((80 / 100) * formData["avarage-monthly-turnover"]).toLocaleString("en-US", { style: "decimal", useGrouping: true })}`}</b>{" "}
									</li>
								</ul>
								{/* MCA - £ {((parseFloat(formData["marchent-turnover"]) || 0) * 1.5).toLocaleString()}
                                <br />1 Year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 1.5).toLocaleString()}
                                <br />2 Year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 2).toLocaleString()}
                                <br />3 year Loan - £{(parseFloat(formData["avarage-monthly-turnover"] || 0) * 3).toLocaleString()}
                                <br /> */}
							</p>
						</div>
						<br />
						<div className="text-xl">
							If you are happy with the offer you can take it and if you don’t like it you can have this offer sit in the email and contact us back if you decided to take the offer. <br />
							<br />
							<p>Are you happy for us to do a credit check on your business to get you an offer?</p>
							<div className="flex gap-8 mt-3">
								<label className="flex items-center">
									<input type="radio" name="creditCheckPermission" value="Yes" checked={formData.creditCheckPermission === "Yes"} onChange={handleChange} className="radio" required />
									<span className="ml-2">Yes</span>
								</label>
								<label className="flex items-center">
									<input type="radio" name="creditCheckPermission" value="No" checked={formData.creditCheckPermission === "No"} onChange={handleChange} className="radio" required />
									<span className="ml-2">No</span>
								</label>
							</div>
						</div>
						<br />
						Thank you for all the information. One of our Funding Manager will get back to you on this and assist you further to get some offers sorted.
						<br />
						<br />
						Sounds fair enough?
						<br />
						Thank you have a Great Day !
						<div className="mt-4 text-right">
							{loading ? (
								<button className="btn" disabled>
									<span className="loading loading-spinner"></span>
									Loading
								</button>
							) : (
								<button type="submit" className="button">
									Submit to Pre Underwriting
								</button>
							)}
						</div>
					</form>
				</div>
				{/* <div className="mb-96">
                    <p className="text-3xl font-semibold">Customer Not Interested?</p>
                    <div className="">
                        <label htmlFor="home-address" className="text-xl mb-1 block mt-2">
                            Why Customer is not interested now?
                        </label>
                        <div>
                            <input id="not-interested" type="text" placeholder="Tell us more about it !" className="textarea textarea-md bg-base-300 w-full max-w-xs mr-2" value={teamNote} onChange={(e) => setTeamNote(e.target.value)} required />
                            <button type="button" onClick={handleNoteSubmit} className="px-6 py-3 bg-primary text-white rounded-md">
                                Send
                            </button>
                        </div>
                    </div>
                </div> */}
			</div>
		</div>
	);
};

export default RequestNewDeal;
